import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { CalculationsDetails } from './livestock-calculations.model';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';

@Component({
  selector: 'app-livestock-calculations',
  templateUrl: './livestock-calculations.component.html',
  styleUrls: ['../../quote.component.css']
})
export class LivestockCalculationsComponent implements OnInit {
  calDetails = new CalculationsDetails();
  @Input() quoteModelDetails;
  @Input() selectedTabIndex;
  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.quoteModelDetails) {
      this.calDetails = this.quoteModelDetails.calculations;
    }

  }

  ngDoCheck() {
    if (this.quoteModelDetails && this.selectedTabIndex == 6) {
      this.calDetails = this.quoteModelDetails.calculations;
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

}
