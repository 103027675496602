<div [formGroup]="commonSection">
    <div class="form_sections">
        <mat-expansion-panel [expanded]="selectedTabIndex == 0 || quote_type > 3">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Quote Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group w-100">
                        <input type="text" readonly required name="policy_start_date_{{selectedTabIndex}}"
                            formControlName="policy_start_date" [disabled]="quote_type == 6" ngModelOptions="{timezone: null}"
                            [value]="quoteDetailsFormControl.policy_start_date.value" placeholder="Policy Start Date"
                            class="form-control form-control-sm datepicker" matInput [min]="currDate" [max]="maxPolicyDate"
                            [ngClass]="{ 'is-invalid': (quoteDetailsFormControl.policy_start_date.touched || submitted) && quoteDetailsFormControl.policy_start_date.errors?.required }"
                            (focus)="policyStartDatepicker.open()" [matDatepicker]="policyStartDatepicker">
                        <mat-datepicker-toggle [for]="policyStartDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #policyStartDatepicker></mat-datepicker>
                        <div class="invalid-feedback"
                            *ngIf="(quoteDetailsFormControl.policy_start_date.touched || submitted) && quoteDetailsFormControl.policy_start_date.errors?.required">
                            Policy start date is required
                        </div>
                    </div>
                </div>
            
                <!-- <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Transaction Type</mat-label>
                        <mat-select formControlName="quote_type" class="form-control form-control-sm" name="quote_type"
                             (selectionChange)="onQuoteTypeChange($event)">
                            <mat-option [value]="1">New Business</mat-option>
                            <mat-option [value]="2">Renewal</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
            </div>
        </mat-expansion-panel>
    </div>
    <div class="form_sections">
        <mat-expansion-panel [expanded]="selectedTabIndex == 0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    About the type of policy required
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-group row mt-5 f13">
                <div class="col-md-9">
                    <mat-radio-group aria-label="Select an option" name="policy_type_{{selectedTabIndex}}"
                        formControlName="policy_type" required (change)="policyTypeChange($event)" [disabled]="quote_type > 3 || disableFields">
                        <div class="row mb-3">
                            <div class="col-md-9">
                                Is a Vendor Public Auction quote required?
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio">
                                    <mat-radio-button [value]="1" ngDefaultControl formControlName="policy_type"
                                        required>
                                        Yes
                                    </mat-radio-button>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-9">
                                Is a Purchaser Public Auction quote required?
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio">
                                    <mat-radio-button [value]="2" ngDefaultControl formControlName="policy_type"
                                        required>
                                        Yes
                                    </mat-radio-button>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-9">
                                Is Private Sale Vendor or home bred cover required?
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio">
                                    <mat-radio-button [value]="3" ngDefaultControl formControlName="policy_type"
                                        required>
                                        Yes
                                    </mat-radio-button>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-9">
                                Is Private Sale Purchaser or home bred cover required?
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio">
                                    <mat-radio-button [value]="4" ngDefaultControl formControlName="policy_type"
                                        required>
                                        Yes
                                    </mat-radio-button>
                                </div>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(quoteDetailsFormControl.policy_type.touched || submitted) && quoteDetailsFormControl.policy_type.errors?.required">
                                    Policy type is required
                                </div>
                            </div>
                        </div>
                    </mat-radio-group>

                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- </form> -->