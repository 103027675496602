import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { LayoutLoginComponent } from './_layouts/layout-login/layout-login.component';
import { LayoutInnerpagesComponent } from './_layouts/layout-innerpages/layout-innerpages.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { QuotePreQualifierComponent } from './quote-pre-qualifier/quote-pre-qualifier.component';

import { LivestockComponent } from './quote/livestock/livestock.component';


const appRoutes: Routes = [  
  {
    path: '', component: LayoutInnerpagesComponent,
    children: [
      { path: '', component: QuotePreQualifierComponent },
      { path: 'quote', component: LivestockComponent },
      { path: 'quote/:id', component: LivestockComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  },  
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
