import { Injectable } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LivestockService {

  constructor(private sharedService: SharedService, private route: ActivatedRoute, private sessionSt: LocalStorageService) { }

  // Get Static Data
  getStaticData(rating_version) {
    return this.sharedService.getAPI('staticdata/livestock/' + rating_version);
  }
  // Get SOF Questions
  getStatementOfFacts(product_type, sof_version) {
    return this.sharedService.getAPI('staticdata/livestock_statement_of_fact/' + environment.LIVESTOCK_ID + '/' + product_type + "/" + sof_version);
  }

  // Post Data
  modelBinding(frmCntrols, modelObj, updateFinancials = 0) {
    modelObj = this.bindValuesToModel(frmCntrols, modelObj, updateFinancials);

    modelObj.broker_id = environment.HHID;
    
    //modelObj.financial_details.uw_fee = 1;
    modelObj.financial_details.uw_fee = modelObj.financial_details.fees;

    return modelObj;
  }

  bindValuesToModel(frmCntrols, modelObj, updateFinancials) {
    // Policy Details
     if (frmCntrols.value.commonSections) {
      modelObj.policy_start_date = frmCntrols.value.commonSections.policy_start_date;
      modelObj.company_id = frmCntrols.value.commonSections.company_id;
      modelObj.mta_reason_id = frmCntrols.value.commonSections.mta_reason_id;
      if (modelObj.livestock_meta) {
        modelObj.livestock_meta.type_of_policy = frmCntrols.value.commonSections.policy_type;
      }
    }

    // About The Proposer
    if (frmCntrols.value.proposer && modelObj.livestock_meta) {
      modelObj.livestock_meta.about_proposer_first_name = frmCntrols.value.proposer.first_name;
      modelObj.livestock_meta.about_proposer_last_name = frmCntrols.value.proposer.last_name;
      modelObj.livestock_meta.about_proposer_email = frmCntrols.value.proposer.email;
    }

    // Livestock Meta
    if (frmCntrols.value.animals) {
      if (modelObj.livestock_meta) {
        modelObj.livestock_meta.animal_count = frmCntrols.value.animals.total_animals_require_insurance;
        modelObj.livestock_meta.livestock_type_id = frmCntrols.value.animals.animals_to_be_insured;
        modelObj.livestock_meta.breed_society_inspection = frmCntrols.value.animals.breed_society_inspection;
        modelObj.livestock_meta.is_nba_breeding_warranty = frmCntrols.value.animals.breeding_warranty_cover;
        modelObj.livestock_meta.breeding_society_name = frmCntrols.value.animals.breeding_society_name;
        modelObj.livestock_meta.claim_count = frmCntrols.value.animals.claims;
      }

      // Livestock Details
      if (frmCntrols.value.animals.animal_details.length > 0) {
        modelObj.livestock_details = [];
        frmCntrols.value.animals.animal_details.forEach((res, index) => {
          let vet_cert = 0;
          if (res.bull_breed == "35546458-ac55-4d2d-aef8-8c75101f8ce2" || res.bull_breed == "35646458-ac55-4d2d-aef8-8c75101f8ce2") {
            if (res.sex == "30146458-ac55-4d2d-aef8-8c75101f8ce2") {
              vet_cert = 1;
            } else if (res.sex == "30246458-ac55-4d2d-aef8-8c75101f8ce2" || res.sex == "30346458-ac55-4d2d-aef8-8c75101f8ce2") {
              vet_cert = 1;
            } else {
              vet_cert = res.vet_cert_provided;
            }
          } else if (res.bull_breed == "35846458-ac55-4d2d-aef8-8c75101f8ce2") {
            vet_cert = 1;
          } else {
            vet_cert = res.vet_cert_provided;
          }
          modelObj.livestock_details.push({
            'id': res.id,
            'quote_id': modelObj.id,
            'lot_no': res.lot_no,
            'tag_no': (res.tag_no) ? res.tag_no : null,
            'name': (res.animal_name) ? res.animal_name : "",
            'horse_purchase_date': (res.dob) ? formatDate(res.dob, 'yyyy-MM-dd', 'en-US') : null,
            'livestock_breed_id': res.bull_breed,
            'livestock_breed_other': res.livestock_breed_other,
            'value': res.sale_price,
            'livestock_cover_id': res.cover_required,
            'livestock_sex_id': (res.sex) ? res.sex : '',
            'livestock_cover_period_id': (res.cover_period) ? res.cover_period : '',
            'is_semen_tested': res.semen_tested,
            'vet_certificate_required': vet_cert,
            'animal_type': res.animal_type,
            'gender': 0,
            'livestock_purchasers': this.getPurchasers(res, modelObj)
          })
        });

      }
      modelObj.breeding_warranty_cover = frmCntrols.value.animals.breeding_warranty_cover;
      modelObj.breeding_society_name = frmCntrols.value.animals.breeding_society_name;

    }

    // Sale/Claim
    if (frmCntrols.value.saleAndClaim) {
      this.sale_claim(modelObj, frmCntrols.value.saleAndClaim);
    }

    if (frmCntrols.value.sale_location) {
      this.sale_claim(modelObj, frmCntrols.value);
    }

    // SOF
    if (frmCntrols.value.sof_details) {
      modelObj.statement_of_facts = [];
      frmCntrols.value.sof_details.forEach((res) => {
        modelObj.statement_of_facts.push({
          'quote_id': modelObj.id,
          'question_id': res.question_id,
          'display_order': res.display_order,
          'response': res.response,
          'response_text': (res.response_text) ? res.response_text : '',
        })
      })
    }

    // Proposer Other Details
    if (frmCntrols.value.pprOtherDetails && frmCntrols.value.pprOtherDetails.proposer_type) {
      let proposer_type = frmCntrols.value.pprOtherDetails.proposer_type;
      this.proposer_other_details(modelObj, frmCntrols.value.pprOtherDetails, proposer_type);
    }
    if (frmCntrols.value.proposer_type_details) {
      let proposer_type = frmCntrols.value.proposer_type;
      this.proposer_other_details(modelObj, frmCntrols.value, proposer_type);
    }

    // Financial Details
    if (updateFinancials == 1) {
      modelObj.financial_details.main_discount = modelObj.calculations.full_main_discount;
      modelObj.financial_details.peliwica_pay_away_commission = modelObj.calculations.peliwica_pay_away_commission;
      modelObj.financial_details.broker_commission = modelObj.calculations.broker_commission;
      modelObj.financial_details.peliwica_retained_commission = modelObj.calculations.peliwica_retained_commission;
      modelObj.financial_details.net_rate_amount = modelObj.calculations.full_class_net_rate_amount;
      modelObj.financial_details.ipt_fee = modelObj.calculations.ipt_fee;
      modelObj.financial_details.ipt_value = modelObj.calculations.full_ipt_gross_amount;
      modelObj.financial_details.policy_premium = modelObj.calculations.full_total_gross_primum;
      modelObj.financial_details.total_premium = modelObj.calculations.full_total_premium;
      modelObj.financial_details.total_policy_cost = modelObj.calculations.full_total_amount_payable;
      modelObj.financial_details.fees = modelObj.calculations.full_underwriting_fee;
      modelObj.financial_details.uw_fee = modelObj.calculations.full_underwriting_fee;
      modelObj.financial_details.broker_amount = modelObj.calculations.full_broker_amount;
      modelObj.financial_details.total_net_amount = modelObj.calculations.full_net_Premium_amount;
      modelObj.financial_details.premium_loading = modelObj.calculations.total_premium_loading;
      modelObj.financial_details.premium_loading_percentage = modelObj.calculations.premium_loading_percentage;
    }
    return modelObj;
  }

  proposer_other_details(modelObj, frmCntrols, proposer_type) {
    if (modelObj.livestock_meta) {
      modelObj.livestock_meta.proposer_type = proposer_type;
      modelObj.policy_holders = [];
      if (proposer_type < 3) {
        // Individual/Joint
        frmCntrols.proposer_type_details.forEach((res) => {
          modelObj.policy_holders.push({
            'quote_id': modelObj.id,
            'first_name': res.proposer_first_name,
            'last_name': res.proposer_last_name,
            'email': res.proposer_email,
            'address': {
              'address1': res.proposer_address_line_1,
              'address2': res.proposer_address_line_2,
              'city': res.proposer_city,
              'country': res.proposer_county,
              'postcode': res.proposer_postcode,
            }
          })
        })

      } else {
        // Company
        modelObj.policy_holders.push({
          'quote_id': modelObj.id,
          'is_micro_enterprise': frmCntrols.commercial_details.is_micro_enterprise,
          'company_name': frmCntrols.commercial_details.company_name,
          'email': frmCntrols.commercial_details.email,
          'phone': frmCntrols.commercial_details.contact_no_1,
          'mobile': frmCntrols.commercial_details.contact_no_2,
          'year_of_establishment': frmCntrols.commercial_details.year_of_establishment,
          'address': {
            'address1': frmCntrols.commercial_details.address_line_1,
            'address2': frmCntrols.commercial_details.address_line_2,
            'city': frmCntrols.commercial_details.city,
            'country': frmCntrols.commercial_details.county,
            'postcode': frmCntrols.commercial_details.postcode,
          }
        })
      }
    }
  }

  sale_claim(modelObj, frmCntrols) {
    modelObj.livestock_meta.sale_date = frmCntrols.sale_date;
    modelObj.livestock_meta.sale_location = frmCntrols.sale_location;
    modelObj.livestock_meta.claim_count = frmCntrols.claims;
    if (frmCntrols.claim_history) {
      modelObj.livestock_claims = [];
      frmCntrols.claim_history.forEach((res, index) => {
        modelObj.livestock_claims.push({
          'quote_id': modelObj.id,
          'livestock_type_id': res.animal_type,
          'claim_amount': res.claim_for,
          'cover_claimed_for': res.cover_claim_for,
        })
      });
    }
  }

  getPurchasers(animals, modelObj) {
    let purchasers = [];
    if (animals.livestock_purchasers) {
      animals.livestock_purchasers.forEach((res, index) => {
        purchasers.push({
          'quote_id': modelObj.id,
          'first_name': res.first_name,
          'last_name': res.last_name,
          'email': res.email,
          'postcode': res.postcode,
          'ownership': res.ownership,
          'address1': res.address1,
          'address2': res.address2,
          'city': res.city,
          'country': res.country,
          'animal_id': animals.id,
        })
      });
    }
    return purchasers;
  }

  /*
  * Bind Financials to Calculation object
  */
  bindCalculations(quoteModelDetails) {
    if (quoteModelDetails.calculations) {
      quoteModelDetails.calculations.full_main_discount = quoteModelDetails.financial_details.main_discount;
      quoteModelDetails.calculations.peliwica_pay_away_commission = quoteModelDetails.financial_details.peliwica_pay_away_commission;
      quoteModelDetails.calculations.broker_commission = quoteModelDetails.financial_details.broker_commission;
      quoteModelDetails.calculations.peliwica_retained_commission = quoteModelDetails.financial_details.peliwica_retained_commission;
      quoteModelDetails.calculations.full_class_net_rate_amount = quoteModelDetails.financial_details.net_rate_amount;
      quoteModelDetails.calculations.ipt_fee = quoteModelDetails.financial_details.ipt_fee;
      quoteModelDetails.calculations.full_ipt_gross_amount = quoteModelDetails.financial_details.ipt_value;
      quoteModelDetails.calculations.full_total_gross_primum = quoteModelDetails.financial_details.policy_premium;
      quoteModelDetails.calculations.full_total_premium = quoteModelDetails.financial_details.total_premium;
      quoteModelDetails.calculations.full_total_amount_payable = quoteModelDetails.financial_details.total_policy_cost;
      quoteModelDetails.calculations.full_underwriting_fee = quoteModelDetails.financial_details.fees;
      quoteModelDetails.calculations.full_broker_amount = quoteModelDetails.financial_details.broker_amount;
      quoteModelDetails.calculations.full_net_Premium_amount = quoteModelDetails.financial_details.total_net_amount;
      quoteModelDetails.calculations.total_premium_loading = quoteModelDetails.financial_details.premium_loading;
      quoteModelDetails.calculations.premium_loading_percentage = quoteModelDetails.financial_details.premium_loading_percentage;
      quoteModelDetails.calculations.old_premium_loading_percentage = quoteModelDetails.financial_details.old_premium_loading_percentage;
      return quoteModelDetails.calculations;
    }
  }

  /*
  * Bind Broker Commissions
  */
  brokerCommissions(dataFrom, data, brokers, calculations, quoteModelDetails, staticData) {
    if (quoteModelDetails && !quoteModelDetails.id) { 
      let brokers_data = brokers.filter(x => x.company_id == environment.HHID);
      if (brokers_data.length > 0) {
        calculations.broker_commission = Number(brokers_data[0].broker_commission);
        calculations.peliwica_pay_away_commission = Number(brokers_data[0].pay_away_commission);
        calculations.peliwica_retained_commission = Number(brokers_data[0].retained_commission);
        calculations.premium_loading_percentage = Number(brokers_data[0].premium_loading);
        calculations.full_underwriting_fee = Number(brokers_data[0].uw_fee);
      }
    }

    if (dataFrom == 'commissions') {
      calculations.broker_commission = Number(data.broker_commission);
      calculations.peliwica_pay_away_commission = Number(data.peliwica_pay_away_commission);
      calculations.peliwica_retained_commission = Number(data.peliwica_retained_commission);
      calculations.full_underwriting_fee = Number(data.full_underwriting_fee);
      calculations.premium_loading_percentage = Number(data.premium_loading_percentage);
    }

    if (staticData) {
      if (staticData.peliwica_commissions) {
        if (staticData.peliwica_commissions.code == "IPT") {
          calculations.ipt_fee = staticData.peliwica_commissions.commission_percentage;
        }
      }
    }
    return calculations;
  }

  /*
  * Bindstatic premiums
  */
  bindStaticCals(formData, calculations, staticData, quoteDetails) {
    // if (quoteDetails.id) {
    //   if (formData.animals.animals_to_be_insured == "10146458-ac55-4d2d-aef8-8c75101f8ce2") {
    //     calculations.policy_minimum_premium = Number(quoteDetails.financial_details.minimum_premium_bull);
    //   }
    //   // Ewe/Ram
    //   if (formData.animals.animals_to_be_insured == "10246458-ac55-4d2d-aef8-8c75101f8ce2") {
    //     calculations.policy_minimum_premium = Number(quoteDetails.financial_details.minimum_premium_sheep);
    //   }
    //   // Heifer/Cow
    //   if (formData.animals.animals_to_be_insured == "10346458-ac55-4d2d-aef8-8c75101f8ce2") {
    //     calculations.policy_minimum_premium = Number(quoteDetails.financial_details.minimum_premium_cow);
    //   }
    // } else {
    if (formData.animals) {
      //Bulls
      if (formData.animals.animals_to_be_insured == "10146458-ac55-4d2d-aef8-8c75101f8ce2") {
        calculations.policy_minimum_premium = Number(staticData.minimum_premium_bull);
      }
      // Ewe/Ram
      if (formData.animals.animals_to_be_insured == "10246458-ac55-4d2d-aef8-8c75101f8ce2") {
        calculations.policy_minimum_premium = Number(staticData.minimum_premium_sheep);
      }
      // Heifer/Cow
      if (formData.animals.animals_to_be_insured == "10346458-ac55-4d2d-aef8-8c75101f8ce2") {
        calculations.policy_minimum_premium = Number(staticData.minimum_premium_cow);
      }
    }
    // }
    return calculations;
  }

  /*
  * Bind Animal Values
  */
  animalValues(animal_details, rating, animals_to_be_insured, type_of_policy) {
    animal_details.forEach((res, index) => {
      let net_rate = 0;
      let is_british_blue = 0;
      let ratings_data = rating.filter(x => x.livestock_type_id == animals_to_be_insured);
      if (res.cover_required) {
        ratings_data = ratings_data.filter(x => x.livestock_cover_id == res.cover_required);
      }
      if (res.cover_period && res.cover_required != '70146458-ac55-4d2d-aef8-8c75101f8ce2') {
        ratings_data = ratings_data.filter(x => x.livestock_cover_period_id == res.cover_period);
      }
      if (res.bull_breed && (res.bull_breed == '30746458-ac55-4d2d-aef8-8c75101f8ce2' || res.bull_breed == '40646458-ac55-4d2d-aef8-8c75101f8ce2')) {
        ratings_data = ratings_data.filter(x => x.is_british_blue == 1);
        is_british_blue = 1;
      }
      if (res.semen_tested && res.cover_required == '70146458-ac55-4d2d-aef8-8c75101f8ce2') {
        let semen_tested = (res.semen_tested == 2) ? 0 : res.semen_tested;
        ratings_data = ratings_data.filter(x => x.is_semen_tested == semen_tested);
      }
      if (res.vet_cert_provided && type_of_policy > 1) {
        let vet_cert_provided = 1;
        if (res.vet_cert_provided == 2) {
          vet_cert_provided = 0;
        }
        ratings_data = ratings_data.filter(x => x.vet_inspection == vet_cert_provided);
      }

      if (res.sex) {
        ratings_data = ratings_data.filter(x => x.livestock_sex_id == res.sex);
      }

      let sum_insured = Number(res.sale_price);
      ratings_data = ratings_data.filter(x => x.lower_sum_insured <= sum_insured && x.upper_sum_insured >= sum_insured && x.is_british_blue == is_british_blue);



      if (ratings_data.length > 0) {
        net_rate = Number(ratings_data[0].rate);
      }
      res.net_rate = net_rate;
      res.sum_insured = sum_insured;

      animal_details[index].net_rate = net_rate;
      animal_details[index].sum_insured = sum_insured;
    });
    return animal_details;
  }

  /*
 * Get Quote Details By Quote ID
 * Type => 0: Quote, 1: Policy
 */
  getQuoteDetailsByQuoteID(quote_id, type = 0) {
    if (quote_id) {
      return this.sharedService.getAPI('livestock/quote/details/' + quote_id + '/' + type);
    }
  }

  /*
  * Enable/Disable MTA/NTU/Lapse
  */
  policyActions(type, calculations) {
    switch (Number(type)) {
      case 2: {
        calculations.is_mta = false;
        calculations.is_cancel = false;
        calculations.is_NTU = false;
        calculations.is_lapse = false;
        break;
      }
      case 3: {
        calculations.is_mta = true;
        calculations.is_cancel = false;
        calculations.is_NTU = false;
        calculations.is_lapse = false;
        break;
      }
      case 4: {
        calculations.is_mta = false;
        calculations.is_cancel = true;
        calculations.is_NTU = false;
        calculations.is_lapse = false;
        break;
      }
      case 5: {
        calculations.is_mta = false;
        calculations.is_cancel = false;
        calculations.is_NTU = false;
        calculations.is_lapse = true;
        break;
      }
      // default: {
      //   calculations.is_mta = false;
      //   calculations.is_cancel = false;
      //   calculations.is_NTU = false;
      //   calculations.is_lapse = false;
      //   break;
      // }
    }
    return calculations;
  }

  /*
  * Bind Local Params of Model from Quote Save Api Response
  */
  bindQouteSaveApiResponse(quoteDetails, response) {
    quoteDetails.mta_sum_policy_premium = response.mta_sum_policy_premium;
    quoteDetails.sum_policy_premium = response.sum_policy_premium;
    quoteDetails.latest_policy_start_date = response.latest_policy_start_date;
    quoteDetails.latest_policy_end_date = response.latest_policy_end_date;
    return quoteDetails;
  }
}

