import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { LivestockService } from '../livestock.service';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { Quote } from '../../../model/quote/quote.model';
import { QuoteService } from '../../quote.service';
import { ignoreElements } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['../../quote.component.css']
})
export class SummaryComponent implements OnInit {
  submitted = false;
  quoteDetailsForm: FormGroup;
  @Input() staticData;
  @Input() brokers;
  @Input() selectedTabIndex;
  @Input() quoteModelDetails;
  quoteDetails = new QuoteVersions();
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  @Output() backToPrevTab = new EventEmitter();
  calculations = new CalculationsDetails();
  @Output() newSelectedTabIndex = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private lvService: LivestockService, private quoteService: QuoteService, 
    private route: ActivatedRoute, private cdRef: ChangeDetectorRef) {
    this.quoteDetailsForm = this.formBuilder.group({
      commonSections: [],
      proposer: [],
      animals: [],
    });
  }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;
    if (this.selectedTabIndex == 3) {
      this.quoteDetails.calculations = this.lvService.bindCalculations(this.quoteModelDetails);
      this.calculations = this.quoteDetails.calculations;
    }
    if (this.route.snapshot.params.quote_type) {
      this.calculations = this.lvService.policyActions(this.route.snapshot.params.quote_type, this.calculations);
      this.quoteDetails.calculations = this.calculations;
      // // Animals
      // let animal_details = [];
      // if (this.quoteModelDetails.livestock_details && this.quoteModelDetails.livestock_details.length > 0) {
      //   animal_details = this.quoteModelDetails.livestock_details;
      // }
      // this.calculations.full_calculate_data(this.quoteDetails, animal_details);
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }


  changedSelectedTabIndex(index) {
    this.newSelectedTabIndex.emit(index);
  }

  backBtn() {
    this.backToPrevTab.emit();
  }

  updatedCalDetails;
  calDetails(data, dataFrom = '') {
    if (this.selectedTabIndex == 3) {
      this.updatedCalDetails = data;
      // Bind Broker commissions    
      this.calculations = this.lvService.brokerCommissions(dataFrom, data, this.brokers, this.calculations, this.quoteModelDetails, this.staticData);
      if (this.route.snapshot.params.quote_type) {
        this.calculations = this.lvService.policyActions(this.route.snapshot.params.quote_type, this.calculations);
      }
      this.quoteDetails.calculations = this.calculations;
      // Animals
      let animal_details = [];
      if (this.quoteDetailsForm.value.animals) {
        if (this.quoteDetailsForm.value.animals.animal_details.length > 0) {
          animal_details = this.quoteDetailsForm.value.animals.animal_details;
          //animal_details = this.lvService.animalValues(animal_details, this.staticData.rating, this.quoteDetailsForm.value.animals.animals_to_be_insured, this.quoteModelDetails.livestock_meta.type_of_policy);
        }
      }
      this.calculations = this.lvService.bindStaticCals(this.quoteDetailsForm.value, this.calculations, this.staticData, this.quoteDetails);
      this.calculations.full_calculate_data(this.quoteDetails, animal_details);
    }
  }

  onSubmit() {
    this.submitted = true;
    this.quoteDetails.calculations = this.calculations;
    let modelObj = this.lvService.modelBinding(this.quoteDetailsForm, this.quoteDetails);
    this.quoteDetails = modelObj;
    if (!this.quoteDetailsForm.valid) {
      this.scrollToError();
      return false;
    }
    this.saveQuote(modelObj);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }


  saveQuote(modelObj) {
    let parent_quote = new Quote();
    parent_quote.id = modelObj.quote_meta_id;
    parent_quote.quote_versions[0] = modelObj;
    let data = {
      quote_data: parent_quote
    };
    this.quoteService.saveLivestockQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            let temp;
            temp = this.quoteDetails;
            this.quoteDetails = response.result.quote_data.quote_versions[0];
            this.quoteDetails = this.lvService.bindQouteSaveApiResponse(this.quoteDetails, response.result.quote_data);
            this.quoteDetails.calculations = this.calculations;
            this.quoteDetails.prev_sequence_number = temp.prev_sequence_number;
            this.quoteDetailsUpdated.emit(this.quoteDetails);
            this.proceedToNextTab.emit();
          }
        });
  }

}
