<footer class="footer">
    <div class="footer-wrap container">
        <div class="w-100 clearfix">
            <a href="https://www.cooperroseltd.co.uk/" target="_blank">   <span class="d-block text-center text-sm-left d-sm-inline-block"> Copyright © 2020. All rights
                reserved.</span></a>
                <a href="https://www.cooperroseltd.co.uk/" target="_blank">    <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Powered by Cooper-Rose Digital 
                    
                   <!-- <i             class="mdi mdi-heart-outline"></i>-->
                </span> </a>
        </div>
    </div>
</footer>