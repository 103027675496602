import { Component, OnInit, Output, EventEmitter, Input, forwardRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder, Validators, FormGroup,
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { ActivatedRoute } from "@angular/router";
import { environment } from '../../../../environments/environment';
import { LivestockService } from '../../../quote/livestock/livestock.service';
import { QuoteService } from '../../../quote/quote.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-livestock-common-sections',
  templateUrl: './livestock-common-sections.component.html',
  styleUrls: ['../../quote.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LivestockCommonSectionsComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LivestockCommonSectionsComponent),
      multi: true
    }
  ]
})
export class LivestockCommonSectionsComponent implements ControlValueAccessor, OnInit {
  @Output() commonSectionFormStatus = new EventEmitter();
  @Output() calDetails = new EventEmitter();
  @Output() changedSelectedTabIndex = new EventEmitter();
  @Input() submitted: boolean;
  @Input() selectedTabIndex;
  @Input() brokers;
  @Input() quoteModelDetails = new QuoteVersions();
  commonSection: FormGroup;

  subscriptions: Subscription[] = [];
  QuoteDetails = new QuoteVersions();
  isBroker: boolean = false;
  calculations = new CalculationsDetails();
  quote_type: number = 0;
  QuoteTypeVisibility: boolean = false;
  currDate = new Date();
  maxPolicyDate;

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef, private sessionSt: LocalStorageService,
    private route: ActivatedRoute, private lvkService: LivestockService, private quoteService: QuoteService) {

  }

  ngOnInit(): void {
    let maxDate = new Date().setDate(new Date().getDate() + 30);
    this.maxPolicyDate = new Date(maxDate);
    this.commonSection = this.fb.group({
      policy_start_date: ['', Validators.required],
      policy_type: [this.QuoteDetails.livestock_meta.type_of_policy, Validators.required],
      quote_type: [this.quote_type]
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.commonSection.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.isBroker = true;
    }

    this.getBrokerCommissionById();
  }

  disableFields: boolean = false;
  ngOnChanges(changes: SimpleChanges) {
    this.QuoteDetails = this.quoteModelDetails;
    if (this.commonSection && this.quoteModelDetails) {

      this.commonSection.patchValue({
        policy_start_date: this.quoteModelDetails.policy_start_date,
        policy_type: Number(this.quoteModelDetails.livestock_meta.type_of_policy),
        quote_type: this.quote_type,
        mta_reason_id: this.quoteModelDetails.mta_reason_id,
      });
    }
    if (this.quoteDetailsFormControl && this.quoteDetailsFormControl.policy_type.value == 0) {
      this.commonSection.get('policy_type').setValue(null);
    }

    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    } else {
      this.quote_type = Number(this.QuoteDetails.type);
    }

    if (this.selectedTabIndex == 6) {
      this.disableFields = true;
    }
  }

  /*
  * On Quote Type Change
  */
  onQuoteTypeChange(event) {
    this.quoteModelDetails.type = event.value;
    this.quote_type = event.value;

  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    // this.quoteDetailsForm.statusChanges.subscribe(
    //   status =>this.commonSectionFormStatus.emit(status)
    //   );
  }

  // get value(): Quote {
  //   return this.quoteDetailsForm.value;
  // }

  // set value(value: Quote) {
  //   this.quoteDetailsForm.setValue(value);
  //   this.onChange(value);
  //   this.onTouched();
  // }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }



  writeValue(value) {
    // if (value) {
    //   this.value = value;
    // }

    // if (value === null) {
    //   this.quoteDetailsForm.reset();
    // }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.commonSection.valid ? null : { commonSections: { valid: false } };
  }



  get quoteDetailsFormControl() {
    if (this.commonSection) {
      return this.commonSection.controls;
    }
  }

  /*
  * On Change policy type set model value
  */
  policyTypeChange(event) {
    if (!this.route.snapshot.params.id) {
      if ((event.value >= 3 && this.QuoteDetails.livestock_meta.type_of_policy <= 2) || (event.value <= 2 && this.QuoteDetails.livestock_meta.type_of_policy >= 3)) {
        this.changedSelectedTabIndex.emit(0);
        this.QuoteDetails.livestock_meta.animal_count = 0;
        this.QuoteDetails.livestock_meta.livestock_type_id = null;
        this.QuoteDetails.livestock_meta.about_proposer_first_name = null;
        this.QuoteDetails.livestock_meta.about_proposer_last_name = null;
        this.QuoteDetails.livestock_meta.about_proposer_email = null;
        this.QuoteDetails.livestock_meta.breed_society_inspection = null;
        this.QuoteDetails.livestock_meta.is_nba_breeding_warranty = null;
        this.QuoteDetails.livestock_meta.breeding_society_name = null;
        this.QuoteDetails.livestock_meta.claim_count = null;
      }
    }
    this.QuoteDetails.livestock_meta.type_of_policy = event.value;
  }

  /*
  * On Change Broker Dropdown
  */
  onChangeBroker(event) {

  }

  //Get Broker Commissions
  getBrokerCommissionById() {
    this.quoteService.getLiveStockBrokerCommission()
      .subscribe(
        (response: any) => {
          if (response.success) {
            let brokers_data = response.result;
            if (brokers_data != null) {
              // if (brokers_data.length > 0) {
              this.calculations.broker_commission = Number(brokers_data.broker_commission);
              this.calculations.peliwica_pay_away_commission = Number(brokers_data.pay_away_commission);
              this.calculations.peliwica_retained_commission = Number(brokers_data.retained_commission);
              this.calculations.premium_loading_percentage = Number(brokers_data.premium_loading);
              this.calculations.full_underwriting_fee = Number(brokers_data.uw_fee);
              // }
              this.QuoteDetails.calculations = this.calculations;
              if (this.commonSection.value.animal_details) {
                //this.calculations.full_calculate_data(this.QuoteDetails,this.commonSection.value.animal_details);
              }
              this.calDetails.emit(this.calculations);
            }
          }
        });
  }

}
