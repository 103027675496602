<div class="calculator">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title class="cal_header text-white">
                <span class="pt-2" *ngIf="!quoteModelDetails.livestock_meta.livestock_type_id">Bull Cover</span>
                <span class="pt-2" *ngIf="quoteModelDetails.livestock_meta.livestock_type_id == '10146458-ac55-4d2d-aef8-8c75101f8ce2'">Bull Cover</span>
                <span class="pt-2" *ngIf="quoteModelDetails.livestock_meta.livestock_type_id == '10246458-ac55-4d2d-aef8-8c75101f8ce2'">Ewe & Ram Cover</span>
                <span class="pt-2" *ngIf="quoteModelDetails.livestock_meta.livestock_type_id == '10346458-ac55-4d2d-aef8-8c75101f8ce2'">Heifer & Cows Cover</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="cal_body p-3">
            <p> 
                <b>Policy premium</b><br>&pound;{{calDetails.full_total_gross_primum}}
            </p>
            <p>
                <b>Insurance premium tax (12%)</b><br>&pound;{{calDetails.full_ipt_gross_amount}}
            </p>
            <p>
                <b>Total premium</b><br>&pound;{{calDetails.full_total_premium}}
            </p>
            <p>
                <b>UW Fees</b><br>&pound;{{calDetails.full_underwriting_fee}}
            </p>
            <p>
                <b>Broker Admin Fees</b><br>&pound;{{calDetails.broker_admin_fee}}
            </p>
            <p>
                <b>Total cost of the policy</b><br>&pound;{{calDetails.full_total_amount_payable}}
            </p>
        </div>
        <div class="btn btn-default btn-gray mt-2 w-100 text-left" *ngIf="quoteModelDetails && quoteModelDetails.quote_ref_number">
            Quote Reference: {{ (quoteModelDetails.is_policy == 1)? quoteModelDetails.policy_ref_number : quoteModelDetails.quote_ref_number }} 
        </div>
    </mat-expansion-panel>
</div>