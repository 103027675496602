<div [formGroup]="qdfrm" class="form_sections">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Proposer Other Details
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-radio-group class="row" aria-label="Select an option" name="proposer_type" formControlName="proposer_type"
            required (change)="onPprTypeChange($event)">
            <div class="col-md-4">
                <div class="quote_radio w-90 mb-3">
                    <mat-radio-button [value]="3">Commercial</mat-radio-button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="quote_radio w-90 mb-3">
                    <mat-radio-button [value]="1">Individual</mat-radio-button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="quote_radio w-90 mb-3">
                    <mat-radio-button [value]="2">Joint Names</mat-radio-button>
                </div>
            </div>
        </mat-radio-group>
        <div class="invalid-feedback" style="font-size: 13px;"
            *ngIf="(frmCtrl.proposer_type.touched || submitted) && frmCtrl.proposer_type.errors?.required">
            Please select an animal.
        </div>

        <!-- Commercial -->
        <div *ngIf="frmCtrl.proposer_type.value == 3">
            <div formGroupName="commercial_details">
                <div class="row">
                    <div class="col-md-6 col-11">
                        <div class="form-group">
                            <label>Is this client a Micro-enterprise?</label>
                            <mat-radio-group class="row" aria-label="Select an option" name="is_micro_enterprise"
                                formControlName="is_micro_enterprise">
                                <div class="col-md-4 col-6">
                                    <div class="quote_radio w-90 mb-3">
                                        <mat-radio-button [value]="1">Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-4 col-6">
                                    <div class="quote_radio w-90 mb-3">
                                        <mat-radio-button [value]="2">No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>Company Details</label>
                        <div class="form-group">
                            <input type="text" name="company_name" placeholder="Company name/ Trading name"
                                class="form-control form-control-sm" formControlName="company_name" required
                                [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.company_name').touched || submitted) && qdfrm.get('commercial_details.company_name').errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(qdfrm.get('commercial_details.company_name').touched || submitted) && qdfrm.get('commercial_details.company_name').errors?.required">
                                Company Name is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>&nbsp;</label>
                        <div class="form-group">
                            <input type="email" name="email" placeholder="Email" class="form-control form-control-sm"
                                formControlName="email" required
                                pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.email').touched || submitted) && qdfrm.get('commercial_details.email').errors?.required }"
                                appTitleCase>
                            <div class="invalid-feedback"
                                *ngIf="qdfrm.get('commercial_details.email').touched || submitted">
                                <div *ngIf="qdfrm.get('commercial_details.email').errors?.required">Email is
                                    required.</div>
                                <div *ngIf="qdfrm.get('commercial_details.email').errors?.pattern">Please
                                    provide a
                                    valid email address</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="contact_no_1" placeholder="Contact number 1"
                                class="form-control form-control-sm" formControlName="contact_no_1" required
                                [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.contact_no_1').touched || submitted) && qdfrm.get('commercial_details.contact_no_1').errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(qdfrm.get('commercial_details.contact_no_1').touched || submitted) && qdfrm.get('commercial_details.contact_no_1').errors?.required">
                                Contact number 1 is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="contact_no_2" placeholder="Contact number 2"
                                class="form-control form-control-sm" formControlName="contact_no_2">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="postcode" placeholder="Postcode"
                                class="form-control form-control-sm" autocomplete="off"
                                (keyup)="qdfrm.get('commercial_details.postcode').errors?'':postcodeLookup($event)"
                                formControlName="postcode" required
                                pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr]?0[Aa]{2})$"
                                [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.postcode').touched || submitted)  && qdfrm.get('commercial_details.postcode').errors?.required }"
                                [matAutocomplete]="auto" appUpperCase>
                            <div *ngIf="(qdfrm.get('commercial_details.postcode').touched || submitted)  && qdfrm.get('commercial_details.postcode').errors?.pattern"
                                class="invalid-feedback">
                                Invalid postcode.
                            </div>
                            <div>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <div *ngIf="!qdfrm.get('commercial_details.postcode').errors">
                                        <mat-option [value]="qdfrm.get('commercial_details.postcode').value"
                                            (click)="onSelectAddress(adrs)" *ngFor="let adrs of addresses">
                                            {{adrs.formatted_address.join(', ')}}</mat-option>
                                    </div>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="address_line_1" placeholder="Line 1"
                                formControlName="address_line_1" class="form-control form-control-sm" appTitleCase
                                [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.address_line_1').touched || submitted) && qdfrm.get('commercial_details.address_line_1').errors?.required }"
                                required>
                            <div *ngIf="(qdfrm.get('commercial_details.address_line_1').touched || submitted) && qdfrm.get('commercial_details.address_line_1').errors?.required"
                                class="invalid-feedback">
                                Address 1 is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="address_line_2" placeholder="Line 2"
                                formControlName="address_line_2" class="form-control form-control-sm" appTitleCase>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="city" placeholder="Town" formControlName="city"
                                class="form-control form-control-sm" appTitleCase
                                [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.city').touched || submitted) && qdfrm.get('commercial_details.city').errors?.required }"
                                required>
                            <div *ngIf="(qdfrm.get('commercial_details.city').touched || submitted) && qdfrm.get('commercial_details.city').errors?.required"
                                class="invalid-feedback">
                                City is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="county" placeholder="County" formControlName="county"
                                class="form-control form-control-sm" appTitleCase
                                [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.county').touched || submitted) && qdfrm.get('commercial_details.county').errors?.required }"
                                required>
                            <div *ngIf="(qdfrm.get('commercial_details.county').touched || submitted) && qdfrm.get('commercial_details.county').errors?.required"
                                class="invalid-feedback">
                                County is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="year_of_establishment" placeholder="Year of Establishment"
                                class="form-control form-control-sm" formControlName="year_of_establishment" required
                                maxlength="4" appNumbers
                                [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.year_of_establishment').touched || submitted) && qdfrm.get('commercial_details.year_of_establishment').errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(qdfrm.get('commercial_details.year_of_establishment').touched || submitted) && qdfrm.get('commercial_details.year_of_establishment').errors?.required">
                                Year of establishment is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Joint Names & Individual -->
        <div *ngIf="frmCtrl.proposer_type.value == 1 || frmCtrl.proposer_type.value == 2">
            <div formArrayName="proposer_type_details" *ngFor="let ptd of podArr.controls; let i=index">
                <div [formGroupName]="i">
                    <label>Proposer {{ (frmCtrl.proposer_type.value == 2)?i+1:"" }}</label>
                    <span *ngIf="i >= 1">
                        <a class="btn btn-danger btn-sm text-white ml-3" (click)="removeProposers(i)"
                            style="padding: 5px;">
                            <i class="mdi mdi-delete"></i>
                        </a>
                    </span>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="proposer_first_name" placeholder="Proposer First Name"
                                    class="form-control form-control-sm" formControlName="proposer_first_name" required>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(ptd.controls.proposer_first_name.touched || submitted) && ptd.controls.proposer_first_name.errors?.required">
                                    First name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="proposer_last_name" placeholder="Proposer Last Name" required
                                    class="form-control form-control-sm" formControlName="proposer_last_name">
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(ptd.controls.proposer_last_name.touched || submitted) && ptd.controls.proposer_last_name.errors?.required">
                                    Last name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="proposer_email" placeholder="Proposer Email"
                                    class="form-control form-control-sm" formControlName="proposer_email" required>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(ptd.controls.proposer_email.touched || submitted) && ptd.controls.proposer_email.errors?.required">
                                    Proposer email is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="proposer_postcode" placeholder="Postcode"
                                    class="form-control form-control-sm" autocomplete="off"
                                    (keyup)="ptd.controls.proposer_postcode.errors?'':postcodeLookup($event)"
                                    pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr]?0[Aa]{2})$"
                                    formControlName="proposer_postcode"
                                    [ngClass]="{ 'is-invalid': (ptd.controls.proposer_postcode.touched || submitted)  && ptd.controls.proposer_postcode.errors?.required }"
                                    [matAutocomplete]="auto" appUpperCase>
                                <div *ngIf="ptd.controls.proposer_postcode.errors?.pattern"
                                    class="invalid-feedback">
                                    Invalid postcode.
                                </div>
                                <div>
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <div *ngIf="!ptd.controls.proposer_postcode.errors">
                                            <mat-option [value]="ptd.controls.proposer_postcode.value"
                                                (click)="onSelectAddress(adrs,i)" *ngFor="let adrs of addresses">
                                                {{adrs.formatted_address.join(', ')}}</mat-option>
                                        </div>
                                    </mat-autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="address_line_1" placeholder="Line 1"
                                    formControlName="proposer_address_line_1" class="form-control form-control-sm"
                                    appTitleCase
                                    [ngClass]="{ 'is-invalid': (ptd.controls.proposer_address_line_1.touched || submitted) && ptd.controls.proposer_address_line_1.errors?.required }"
                                    required>
                                <div *ngIf="(ptd.controls.proposer_address_line_1.touched || submitted) && ptd.controls.proposer_address_line_1.errors?.required"
                                    class="invalid-feedback">
                                    Address 1 is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="proposer_address_line_2" placeholder="Line 2"
                                    formControlName="proposer_address_line_2" class="form-control form-control-sm"
                                    appTitleCase>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="proposer_city" placeholder="Town"
                                    formControlName="proposer_city" class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': (ptd.controls.proposer_city.touched || submitted) && ptd.controls.proposer_city.errors?.required }"
                                    required>
                                <div *ngIf="(ptd.controls.proposer_city.touched || submitted) && ptd.controls.proposer_city.errors?.required"
                                    class="invalid-feedback">
                                    City is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="proposer_county" placeholder="County"
                                    formControlName="proposer_county" class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': (ptd.controls.proposer_county.touched || submitted) && ptd.controls.proposer_county.errors?.required }"
                                    required>
                                <div *ngIf="(ptd.controls.proposer_county.touched || submitted) && ptd.controls.proposer_county.errors?.required"
                                    class="invalid-feedback">
                                    County is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="frmCtrl.proposer_type.value == 2">
                <div class="col-md-4">
                    <div class="form-group">
                        <a (click)="addProposer()" class="btn btn-success">Add another proposer</a>
                    </div>
                </div>
            </div>
        </div>

    </mat-expansion-panel>
</div>