<div class="horizontal-menu">
    <nav class="navbar top-navbar p-0 row">
        <div class="container">
            <div class="col-md-3">
                <a class="ml-2" href="/" *ngIf="logo_url">
                    <img src="{{ logo_url }}" alt="logo" width="210" class="img-fluid">
                </a>
            </div>
            <div class="col-md-6 navbar-menu-wrapper d-flex align-items-center justify-content-between">
               

            </div>

        </div>
    </nav>
</div>