<form [formGroup]="qdfrm" (ngSubmit)="onSubmit(helpTextPopupModal)">
    <div class="row">
        <div class="col-md-8">
            <app-livestock-common-sections formControlName="commonSections" [brokers]="brokers" [submitted]="submitted"
                [selectedTabIndex]="selectedTabIndex" [quoteModelDetails]="quoteModelDetails"
                (calDetails)="calDetails($event)" (changedSelectedTabIndex)="changedSelectedTabIndex($event)">
            </app-livestock-common-sections>
            <app-proposer formControlName="proposer" [submitted]="submitted" [selectedTabIndex]='selectedTabIndex'
                [quoteModelDetails]="quoteModelDetails"></app-proposer>
            <app-proposer-other-details formControlName="pprOtherDetails" [submitted]="submitted"
                [selectedTabIndex]="selectedTabIndex" [quoteModelDetails]="quoteModelDetails">
            </app-proposer-other-details>


            <!-- Sale And Claim Details -->
            <app-sale-and-claims formControlName="saleAndClaim" [staticData]="staticData" [submitted]="submitted"
                [selectedTabIndex]="selectedTabIndex" [quoteModelDetails]="quoteModelDetails"></app-sale-and-claims>

            <app-animals-insurance formControlName="animals" [submitted]="submitted" [staticData]="staticData"
                [selectedTabIndex]='selectedTabIndex' [quoteModelDetails]="quoteModelDetails"
                (calDetails)="calDetails($event)">
            </app-animals-insurance>

            <button class="btn btn-success btn-sm" [disabled]="quoteModelDetails.isButton">Proceed</button>
        </div>
        <div class="col-md-4">
            <app-livestock-calculations [quoteModelDetails]="quoteModelDetails" [selectedTabIndex]="selectedTabIndex">
            </app-livestock-calculations>
        </div>
    </div>
</form>
<ng-template #helpTextPopupModal let-modal>
    <div class="modal-body">
        <div class="helptext">Policy details saved.</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<!-- <p>
    Form is {{qdfrm.valid ? 'Valid' : 'Invalid'}}
</p>
<pre>
            {{qdfrm.value | json}}
            </pre> -->