import { Component, OnInit, Input, forwardRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import {
  FormBuilder, Validators, FormGroup,
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-proposer',
  templateUrl: './proposer.component.html',
  styleUrls: ['../../quote.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProposerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProposerComponent),
      multi: true
    }
  ]
})
export class ProposerComponent implements OnInit {
  QuoteDetails = new QuoteVersions();
  @Input() submitted: boolean;
  @Input() selectedTabIndex;
  @Input() quoteModelDetails = new QuoteVersions();
  proposerSection: FormGroup;
  subscriptions: Subscription[] = [];


  constructor(private titlecasePipe: TitleCasePipe, private fb: FormBuilder, private route: ActivatedRoute, private cdRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.proposerSection = this.fb.group({
      first_name: [this.quoteModelDetails.livestock_meta.about_proposer_first_name, Validators.required],
      last_name: [this.quoteModelDetails.livestock_meta.about_proposer_last_name, Validators.required],
      email: [this.quoteModelDetails.livestock_meta.about_proposer_email, Validators.required],
    });
    //this.proposerSection.patchValue(this.quote.policy_holder);
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.proposerSection.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  quote_type: number = 0;
  ngOnChanges(changes: SimpleChanges) {
    this.QuoteDetails = this.quoteModelDetails;
    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    }
    if (this.proposerSection && this.quoteModelDetails.livestock_meta) {
      this.proposerSection.patchValue({
        first_name: this.quoteModelDetails.livestock_meta.about_proposer_first_name,
        last_name: this.quoteModelDetails.livestock_meta.about_proposer_last_name,
        email: this.quoteModelDetails.livestock_meta.about_proposer_email,
      });
    }
  }

  firstnameToTotleCase(event) {
    this.QuoteDetails.policy_holder.first_name = this.titlecasePipe.transform(event.target.value);
  }
  lastnameToTotleCase(event) {
    this.QuoteDetails.policy_holder.last_name = this.titlecasePipe.transform(event.target.value);
  }

  get proposerSectionControl() {
    return this.proposerSection.controls;
  }

  // get value(): Quote {
  //   return this.proposerSection.value;
  // }

  // set value(value: Quote) {
  //   this.proposerSection.setValue(value);
  //   this.onChange(value);
  //   this.onTouched();
  // }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    // if (value) {
    //   this.value = value;
    // }

    // if (value === null) {
    //   this.proposerSection.reset();
    // }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.proposerSection.valid ? null : { proposer: { valid: false } };
  }

  updateModel() {
    this.quoteModelDetails.livestock_meta.about_proposer_first_name = this.proposerSection.get('first_name').value;
    this.quoteModelDetails.livestock_meta.about_proposer_last_name = this.proposerSection.get('last_name').value;
    this.quoteModelDetails.livestock_meta.about_proposer_email = this.proposerSection.get('email').value;
  }

}
