import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private sharedService: SharedService, private sessionSt: LocalStorageService) { }

}
