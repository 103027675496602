import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { FormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {DataTablesModule} from 'angular-datatables';
import {MatRadioModule} from '@angular/material/radio'; 
import {MatSliderModule} from '@angular/material/slider';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatSelectModule} from '@angular/material/select'; 
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';


import { ModalModule } from './_modal';

import { SharedService } from './services/shared.service';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptorService } from './services/interceptors/loader-interceptor.service';

import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './_layouts/sidebar/sidebar.component';
import { LayoutLoginComponent } from './_layouts/layout-login/layout-login.component';
import { LayoutInnerpagesComponent } from './_layouts/layout-innerpages/layout-innerpages.component';
import { AppRoutingModule } from './app-routing.module';
import { LoaderComponent } from './_layouts/loader/loader.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatDatepickerModule } from '@angular/material/datepicker'; 
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
const moment = _rollupMoment || _moment;
import {MatExpansionModule} from '@angular/material/expansion';
import { AgGridModule } from 'ag-grid-angular';
import { AngularEditorModule } from '@kolkov/angular-editor';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { DecimalNumbersDirective } from './directives/decimal-numbers.directive';
import { TitleCasePipe } from '@angular/common';
import { TitleCaseDirective } from './directives/title-case.directive';
import { DialogComponent } from './_layouts/dialog/dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { NumbersDirective } from './directives/numbers.directive';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EditorModule } from "@tinymce/tinymce-angular";
import { QuotePreQualifierComponent } from './quote-pre-qualifier/quote-pre-qualifier.component';
import { LivestockComponent } from './quote/livestock/livestock.component';
import { CoverOptionsComponent } from './quote/livestock/cover-options/cover-options.component';
import { LivestockCommonSectionsComponent } from './quote/livestock/livestock-common-sections/livestock-common-sections.component';
import { QuotationComponent } from './quote/livestock/quotation/quotation.component';
import { ProposerComponent } from './quote/livestock/proposer/proposer.component';
import { AnimalsInsuranceComponent } from './quote/livestock/animals-insurance/animals-insurance.component';
import { SummaryComponent } from './quote/livestock/summary/summary.component';
import { LivestockCalculationsComponent } from './quote/livestock/livestock-calculations/livestock-calculations.component';
import { LivestockSofComponent } from './quote/livestock/livestock-sof/livestock-sof.component';
import { LvPaymentConfirmationComponent } from './quote/livestock/lv-payment-confirmation/lv-payment-confirmation.component';
import { LvPolicyPaymentComponent } from './quote/livestock/lv-policy-payment/lv-policy-payment.component';
import { LvFullDetailsComponent } from './quote/livestock/lv-full-details/lv-full-details.component';
import { SaleFullDetailsComponent } from './quote/livestock/sale-full-details/sale-full-details.component';
import { SaleQuoteDetailsComponent } from './quote/livestock/sale-quote-details/sale-quote-details.component';
import { SaleAndClaimsComponent } from './quote/livestock/sale-and-claims/sale-and-claims.component';
import { ProposerOtherDetailsComponent } from './quote/livestock/proposer-other-details/proposer-other-details.component';
import { QuotationConfirmationComponent } from './quote/livestock/quotation-confirmation/quotation-confirmation.component';
import { CommissionsComponent } from './quote/livestock/commissions/commissions.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LayoutLoginComponent,
    LayoutInnerpagesComponent,
    LoaderComponent,
    DecimalNumbersDirective,
    TitleCaseDirective,
    DialogComponent,
    UpperCaseDirective,
    NumbersDirective,

    PageNotFoundComponent,
    QuotePreQualifierComponent,
    LivestockComponent,
    CoverOptionsComponent,
    LivestockCommonSectionsComponent,
    QuotationComponent,
    ProposerComponent,
    AnimalsInsuranceComponent,
    SummaryComponent,
    LivestockCalculationsComponent,
    LivestockSofComponent,
    LvPaymentConfirmationComponent,
    LvPolicyPaymentComponent,
    LvFullDetailsComponent,
    SaleFullDetailsComponent,
    SaleQuoteDetailsComponent,
    SaleAndClaimsComponent,
    ProposerOtherDetailsComponent,
    QuotationConfirmationComponent,
    CommissionsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    ReactiveFormsModule,
    ModalModule,    
    AppRoutingModule,
    FormsModule,
    MatTabsModule,
    BrowserAnimationsModule,
    DataTablesModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatExpansionModule,
    AgGridModule.withComponents([]),
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDialogModule,
    NgbModule,
    EditorModule,
    AngularEditorModule 
  ],
  providers: [SharedService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},TitleCasePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
