import { Component, OnInit, SimpleChanges, Input, forwardRef, Output, EventEmitter, ChangeDetectorRef, ElementRef } from '@angular/core';
import { LivestockService } from '../livestock.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder, Validators, FormGroup, FormArray,
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS
} from '@angular/forms';
import { Quote } from '../../../model/quote/quote.model';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { QuoteService } from '../../quote.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-livestock-sof',
  templateUrl: './livestock-sof.component.html',
  styleUrls: ['../../quote.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LivestockSofComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LivestockSofComponent),
      multi: true
    }
  ]
})
export class LivestockSofComponent implements ControlValueAccessor, OnInit {
  @Input() selectedTabIndex;
  @Input() quoteModelDetails;
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  @Output() backToPrevTab = new EventEmitter();

  quoteDetails = new QuoteVersions();
  sofForm: FormGroup;
  submitted = false;
  sof_details: FormArray;
  cover_required: string = "";
  cover_period: string = "";
  vet_cert_provided: number = 0;
  calculations = new CalculationsDetails();

  constructor(private lvkService: LivestockService, private modalService: NgbModal, private el: ElementRef,
    private fb: FormBuilder, private quoteService: QuoteService, private cdRef: ChangeDetectorRef, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sofForm = this.fb.group({
      sof_details: this.fb.array([]),
    });

  }


  statementOfFacts = [];

  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;
    if (this.route.snapshot.params.id) {
      if (this.selectedTabIndex == 2) {
        this.quoteDetails.calculations = this.lvkService.bindCalculations(this.quoteModelDetails);
        this.calculations = this.quoteDetails.calculations;
      }
    }

    if (this.quoteDetails.sofIsDisabled) {
      this.quoteDetails.sofIsQuoteEdit = false;
    }
    else {
      this.quoteDetails.sofIsQuoteEdit = true;
    }
    if (this.selectedTabIndex == 2 || this.selectedTabIndex == 3) {
      if (this.quoteDetails.livestock_details != null) {
        this.quoteDetails.livestock_details.isAnimalType = true;
      }

      let animal_type = 0;
      // Animal Type on based on bulls,eves and Cows, Type 8 is for sales and purchase
      if (this.quoteDetails) {
        let firstAnimalData;
        if (this.quoteDetails.livestock_details && this.quoteDetails.livestock_details.length > 0) {
          firstAnimalData = this.quoteDetails.livestock_details[0];
        }

        if (this.quoteDetails.livestock_meta != null) {
          if (this.quoteDetails.livestock_meta.type_of_policy == 1) {

            if ((this.quoteDetails.livestock_meta.livestock_type_id == '10146458-ac55-4d2d-aef8-8c75101f8ce2')
              && (firstAnimalData.livestock_cover_id == '70146458-ac55-4d2d-aef8-8c75101f8ce2' || firstAnimalData.livestock_cover_id == '70246458-ac55-4d2d-aef8-8c75101f8ce2')) {
              animal_type = 5;
            }
            else if (this.quoteDetails.livestock_meta.livestock_type_id == '10246458-ac55-4d2d-aef8-8c75101f8ce2' && firstAnimalData.livestock_cover_id == '70346458-ac55-4d2d-aef8-8c75101f8ce2') {
              // if (firstAnimalData.vet_certificate_required == 1) {
              //   //animal_type = 6;
              // }
               if (firstAnimalData.vet_certificate_required == 1 || firstAnimalData.vet_certificate_required == 2 || firstAnimalData.vet_certificate_required == 3) {
                animal_type = 7;
              }
            }
          }
          else if (this.quoteDetails.livestock_meta.type_of_policy == 2) {
            if (this.quoteDetails.livestock_meta.livestock_type_id == '10146458-ac55-4d2d-aef8-8c75101f8ce2') {
              // if ((firstAnimalData.livestock_cover_id == '70246458-ac55-4d2d-aef8-8c75101f8ce2')
              //   && (firstAnimalData.livestock_cover_period_id == '95646458-ac55-4d2d-aef8-8c75101f8ce2' || firstAnimalData.livestock_cover_period_id == '95746458-ac55-4d2d-aef8-8c75101f8ce2')) {
              //   animal_type = 9;
              // }
              if ((firstAnimalData.livestock_cover_id == '70246458-ac55-4d2d-aef8-8c75101f8ce2')
              && (firstAnimalData.livestock_cover_period_id == '95246458-ac55-4d2d-aef8-8c75101f8ce2' || firstAnimalData.livestock_cover_period_id == '95346458-ac55-4d2d-aef8-8c75101f8ce2' 
              || firstAnimalData.livestock_cover_period_id == '95446458-ac55-4d2d-aef8-8c75101f8ce2')) {
                animal_type = 9;
              }
            }
            // else if ((this.quoteDetails.livestock_meta.livestock_type_id == '10346458-ac55-4d2d-aef8-8c75101f8ce2')
            //   && (firstAnimalData.livestock_cover_period_id == '95746458-ac55-4d2d-aef8-8c75101f8ce2')) {
            //   animal_type = 9;
            // }
            else if (this.quoteDetails.livestock_meta.livestock_type_id == '10346458-ac55-4d2d-aef8-8c75101f8ce2') {
              animal_type = 9;
            }
            else if (this.quoteDetails.livestock_meta.livestock_type_id == '10246458-ac55-4d2d-aef8-8c75101f8ce2') {
              if (firstAnimalData.livestock_cover_id == '70346458-ac55-4d2d-aef8-8c75101f8ce2') {
                if (firstAnimalData.vet_certificate_required == 1 || firstAnimalData.vet_certificate_required == 3) {
                  animal_type = 9;
                }
                else if (firstAnimalData.vet_certificate_required == 2) {
                  animal_type = 10;
                }
              }
              // else if (firstAnimalData.vet_certificate_required == 1) {
              //   animal_type = 6;
              // }
              else if (firstAnimalData.vet_certificate_required == 1 || firstAnimalData.vet_certificate_required == 2) {
                animal_type = 7;
              }

            }
          }
          else if ((this.quoteDetails.livestock_meta.type_of_policy == 3) && (this.quoteDetails.livestock_meta.livestock_type_id == '10146458-ac55-4d2d-aef8-8c75101f8ce2') && (firstAnimalData.livestock_cover_id == '70146458-ac55-4d2d-aef8-8c75101f8ce2')) {
            animal_type = 11;
          }
          else if ((this.quoteDetails.livestock_meta.livestock_type_id != '10146458-ac55-4d2d-aef8-8c75101f8ce2' && this.quoteDetails.livestock_meta.type_of_policy == 3) || (this.quoteDetails.livestock_meta.type_of_policy == 4)) {
            animal_type = 8;
          }
        }

      }

      if (animal_type > 0) {
        this.lvkService.getStatementOfFacts(animal_type,this.quoteDetails.sof_version)
          .subscribe(
            (response: any) => {
              if (response.success) {
                if (this.sof_details) {
                  this.sof_details.clear();
                }

                response.result.statement_of_fact.forEach((res, index) => {
                  let sof;

                  if (this.quoteDetails.statement_of_facts.length > 0) {
                    this.quoteDetails.statement_of_facts.forEach((res1, index1) => {
                      if (res1.question_id == res.id) {
                        sof = this.fb.group({
                          question_id: res.id,
                          display_order: res.display_order,
                          response: [res1.response, Validators.required],
                          response_text: [res1.response_text, Validators.required],
                        });
                      }

                    });
                  } else {
                    sof = this.fb.group({
                      question_id: res.id,
                      display_order: res.display_order,
                      response: ['', Validators.required],
                      response_text: ['', Validators.required],
                    });
                  }

                  this.sof_details = this.sofForm.get('sof_details') as FormArray;
                  this.sof_details.push(sof);
                  // this.sof_details.controls[index].get("response_text").disable();
                });
                this.statementOfFacts = response.result.statement_of_fact;
                if (this.sof_details) {
                  this.sof_details.controls.forEach((res) => {
                    if (Number(res.get("response").value) == 2) {
                      res.get("response_text").enable();
                    } else {
                      res.get("response_text").disable();
                    }
                  })
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        // this._snackBar.open('No animal selected.', '', {
        //   duration: 5000,
        //   panelClass: 'text-danger'
        // });
      }
    }

  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  /*
 * HELP TEXT POPUP
 */
  helptext = '';
  helpTextPopup(content, text) {
    const modalRef = this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
  * ControlValueAccessor methods
  * registerOnChange, writeValue, registerOnTouched
  * Use to pass data to parent form
  */
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }
  writeValue(value) {

  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /*
  * Indicate parent form if animal form is valid or not.
  */
  validate(_: FormControl) {
    return this.sofForm.valid ? null : { animals: { valid: false } };
  }

  /*
  * Access form control values
  */
  get frmCtrl() {
    return this.sofForm.controls;
  }
  sofDetails() {
    return (this.sofForm.get('sof_details') as FormArray).controls;
  }

  /*
  * Response Change:
  * Disbale response text field if 'No' selected
  */
  onResponseChange(event, i) {
    if (event.value == 2) {
      this.sof_details.controls[i].get("response_text").enable();
    } else {
      this.sof_details.controls[i].get("response_text").disable();
    }
  }

  backBtn() {
    this.backToPrevTab.emit();
  }

  onSubmit() {
    this.submitted = true;
    this.quoteDetails.calculations = this.calculations;
    let modelObj = this.lvkService.modelBinding(this.sofForm, this.quoteDetails);
    this.quoteDetails = modelObj;
    if (!this.sofForm.valid) {
      this.scrollToError();
      return false;
    }

    this.saveQuote(modelObj);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

  saveQuote(modelObj) {
    let parent_quote = new Quote();
    parent_quote.id = modelObj.quote_meta_id;
    parent_quote.quote_versions[0] = modelObj;
    parent_quote.quote_versions[0].insurance_status_id = '80c0db90-ba5d-4387-b09a-4c008a664932';
    parent_quote.quote_versions[0].quote_page_index = 3;
    let data = {
      quote_data: parent_quote
    };
    this.quoteService.saveLivestockQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            let temp;
            temp = this.quoteDetails;
            this.quoteDetails = response.result.quote_data.quote_versions[0];
            this.quoteDetails.is_declined = response.result.quote_data.is_declined;
            this.quoteDetails.is_referred = response.result.quote_data.is_referred;
            this.quoteDetails.declined_reasons = response.result.quote_data.declined_reasons;
            this.quoteDetails.calculations = new CalculationsDetails();
            this.quoteDetails = this.lvkService.bindQouteSaveApiResponse(this.quoteDetails, response.result.quote_data);
            this.quoteDetails.calculations = this.lvkService.bindCalculations(response.result.quote_data.quote_versions[0]);
            this.calculations = this.lvkService.policyActions(this.route.snapshot.params.quote_type, this.calculations);
            this.quoteDetails.prev_sequence_number = temp.prev_sequence_number;
            this.quoteDetailsUpdated.emit(this.quoteDetails);
            this.proceedToNextTab.emit();
          }
        });
  }

}
