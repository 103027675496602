<form [formGroup]="sofForm" (ngSubmit)="onSubmit()" *ngIf="statementOfFacts"> 
    <div class="sof" formArrayName="sof_details" *ngFor="let statementOfFact of statementOfFacts; let i = index;">
        <!-- [hidden]="(quoteDetails.livestock_details[0].semen_tested =='' || quoteDetails.livestock_details[0].semen_tested == 2) && (statementOfFact.id =='156d89da-0f2b-4a8f-a766-c53df74f4958')" -->
        <div [formGroupName]="i">
            <div class="form-group row">
                <div class="col-md-12">
                    <div class="quote_radio w-90">
                        <label class="p-3">
                            {{statementOfFact.description}}
                        </label>
                    </div>
                    <span (click)="helpTextPopup(content,statementOfFact.description)"><i
                            class="mdi mdi-comment-question-outline ml-1"></i></span>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4 col-11">
                    <mat-radio-group aria-label="Select an option" name="response_{{i}}" formControlName="response"
                        required (change)="onResponseChange($event, i)">
                        <div class="row">
                            <div class="col-6">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="1" [disabled] ="quoteDetails.sofIsDisabled" >Yes </mat-radio-button>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="2" [disabled] ="quoteDetails.sofIsDisabled">No</mat-radio-button>
                                </div>
                            </div>
                        </div>
                    </mat-radio-group>
                    <div class="invalid-feedback" style="font-size: 13px;"
                        *ngIf="sof_details.controls[i] && (sof_details.controls[i].get('response').touched || submitted) && sof_details.controls[i].get('response').errors?.required">
                        Please choose an option.
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="frmCtrl.sof_details.value[i] && ((frmCtrl.sof_details.value[i].response == 1 && statementOfFact.show_textbox == 1) || (frmCtrl.sof_details.value[i].response == 2 && statementOfFact.show_textbox == 0))">
                <div class="col-md-11 pr-5">
                    <textarea name="response_text" class="form-control" formControlName="response_text"
                        placeholder="Please enter additional details this will be referred to our underwriters"
                        #response_text [readonly] ="quoteDetails.sofIsDisabled">
                </textarea>
                <div class="invalid-feedback" style="font-size: 13px;"
                        *ngIf="(sof_details.controls[i].get('response_text').touched || submitted) && sof_details.controls[i].get('response_text').errors?.required">
                        Response text is required.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="(selectedTabIndex == 2 || selectedTabIndex == 3) && (quoteDetails.sofIsQuoteEdit)">
        <a class="btn btn-default btn-gray mr-3" (click)="backBtn()">Back</a>
        <input type="submit" name="proceed" class="btn btn-success btn-gray" value="Submit for Underwriting">
    </div>
</form>
<ng-template #content let-modal>
    <div class="modal-body">
        <div [innerHTML]="helptext"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<!-- <p>
    Form is {{sofForm.valid ? 'Valid' : 'Invalid'}}
</p> -->
<!-- <pre>
    {{sofForm.value | json}}
    </pre> -->