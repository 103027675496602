import { Component, OnInit } from '@angular/core';
import { LivestockService } from './livestock.service';
import { QuoteVersions } from "../../model/quote/quote_versions.model";
import { QuoteService } from '../quote.service';
import { ActivatedRoute } from "@angular/router";
import { CalculationsDetails } from './livestock-calculations/livestock-calculations.model';

@Component({
  selector: 'app-livestock',
  templateUrl: './livestock.component.html',
  styleUrls: ['./livestock.component.css']
})
export class LivestockComponent implements OnInit {
  staticData;
  selectedIndex: number = 0;
  brokers = [];
  quote_type: number = 0;

  constructor(private LvkService: LivestockService, private quoteService: QuoteService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    // GET STATIC DATA
    this.getStaticData(0);

    // Get Broker data
    this.quoteService.getLiveStockBrokerCommission()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.brokers.push(response.result)
          }
        });

    // GET QUOTE DETAILS
    if (this.route.snapshot.params.id) {
      let type = 0;
      if (this.route.snapshot.params.quote_type) {
        this.quote_type = this.route.snapshot.params.quote_type;
        type = 1;
      }
      this.LvkService.getQuoteDetailsByQuoteID(this.route.snapshot.params.id, type)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.quoteModelDetails = response.result;
              this.quoteModelDetails.calculations = new CalculationsDetails();
              this.quoteModelDetails.calculations = this.LvkService.bindCalculations(response.result);
              this.getStaticData(response.result.rating_version);
              if (this.route.snapshot.params.quote_type > 3) {
                this.selectedIndex = 1;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }



  }

  getStaticData(rating_version) {
    this.LvkService.getStaticData(rating_version)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.staticData = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * Tab Change
  */
  tabChanged(event) {
    this.selectedIndex = event.index;
  }

  newSelectedTabIndex(index) {
    this.selectedIndex = index;
  }

  quoteModelDetails = new QuoteVersions();
  quoteDetailsUpdated(quoteDetails) {
    this.quoteModelDetails = null;
    this.quoteModelDetails = quoteDetails;

  }

  proceedToNextTab() {
    this.selectedIndex += 1;
  }
  backToPrevTab() {
    this.selectedIndex -= 1;
  }
  isSofTab;
  isSofTabEnable(isSofTab) {
    this.isSofTab = isSofTab
  }

}
