import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Quote } from '../../../model/quote/quote.model';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { LivestockService } from '../livestock.service';
import { QuoteService } from '../../quote.service';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['../../quote.component.css']
})
export class QuotationComponent implements OnInit {
  submitted = false;
  quoteDetailsForm: FormGroup;
  @Input() staticData;
  @Input() selectedTabIndex;
  @Input() quoteModelDetails;
  @Input() brokers;
  quoteDetails = new QuoteVersions();
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  @Output() backToPrevTab = new EventEmitter();
  @Output() isSofTabEnable = new EventEmitter(false);
  @Output() newSelectedTabIndex = new EventEmitter();
  isAnimalExists: boolean = false;
  calculations = new CalculationsDetails();
  quote_type: number = 0;

  constructor(private formBuilder: FormBuilder, private cdRef: ChangeDetectorRef, private lvService: LivestockService, private router: Router,
    private el: ElementRef, private quoteService: QuoteService, private route: ActivatedRoute, private sessionSt: LocalStorageService) {
    this.quoteDetailsForm = this.formBuilder.group({
      commonSections: [],
      proposer: [],
      animals: [],
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;    
    if (this.route.snapshot.params.id) {
      if (this.selectedTabIndex == 1) {
        this.quoteDetails.calculations = this.lvService.bindCalculations(this.quoteModelDetails);
        this.calculations = this.quoteDetails.calculations;
      }
    } else {
      //if (this.selectedTabIndex == 1) {
        this.calDetails(this.quoteModelDetails.calculations);
     // }
    }

    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    } else {
      this.quote_type = Number(this.quoteDetails.type);
    }

    if (this.route.snapshot.params.quote_type) {
      this.calculations = this.lvService.policyActions(this.quote_type, this.calculations);
      // Animals
      let animal_details = [];
      if (this.quoteModelDetails.livestock_details && this.quoteModelDetails.livestock_details.length > 0) {
        animal_details = this.quoteModelDetails.livestock_details;

      }
      //this.calculations.full_calculate_data(this.quoteDetails, animal_details);
    }


  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  changedSelectedTabIndex(index) {
    this.newSelectedTabIndex.emit(index);
  }

  backBtn() {
    this.backToPrevTab.emit();
  }

  updatedCalDetails;
  calDetails(data, dataFrom = '') {

    this.updatedCalDetails = data;
    // Bind Broker commissions    
  //   if (data.status) {
  //    this.calculations = new CalculationsDetails();
  //  } else {
      this.calculations = this.lvService.brokerCommissions(dataFrom, data, this.brokers, this.calculations, this.quoteModelDetails, this.staticData);
  //  }
    this.quoteDetails.calculations = this.calculations;
    // Animals
    let animal_details = [];
    if (this.quoteDetailsForm.value.animals) {
      if (this.quoteDetailsForm.value.animals.animal_details.length > 0) {
        this.quoteModelDetails.isButton = false;
        animal_details = this.quoteDetailsForm.value.animals.animal_details;
        this.quoteDetailsForm.value.animals.animal_details.forEach((res) => {
          if(this.quoteModelDetails.livestock_meta.type_of_policy == 2 && this.quoteDetailsForm.value.animals.animals_to_be_insured == "10246458-ac55-4d2d-aef8-8c75101f8ce2" && res.sale_price > 1000 && res.vet_cert_provided == 2) {
            this.quoteModelDetails.isButton = true;
          } else if(this.quoteModelDetails.livestock_meta.type_of_policy == 4 && (this.quoteDetailsForm.value.animals.animals_to_be_insured == "10146458-ac55-4d2d-aef8-8c75101f8ce2" || this.quoteDetailsForm.value.animals.animals_to_be_insured == "10346458-ac55-4d2d-aef8-8c75101f8ce2") && res.sale_price > 2000 && res.vet_cert_provided == 2) {
            this.quoteModelDetails.isButton = true;
          } else if(this.quoteModelDetails.livestock_meta.type_of_policy == 4 && this.quoteDetailsForm.value.animals.animals_to_be_insured == "10246458-ac55-4d2d-aef8-8c75101f8ce2" && res.sale_price > 3000 && res.vet_cert_provided == 2) {
            this.quoteModelDetails.isButton = true;
          }
        })
        
        //animal_details = this.lvService.animalValues(animal_details, this.staticData.rating, this.quoteDetailsForm.value.animals.animals_to_be_insured, this.quoteModelDetails.livestock_meta.type_of_policy);
      }
    }
    if (data.clearStatus) { 
      animal_details = [];
    }

    this.calculations = this.lvService.bindStaticCals(this.quoteDetailsForm.value, this.calculations, this.staticData, this.quoteDetails);
    this.calculations.full_calculate_data(this.quoteDetails, animal_details);
  }

  onSubmit() {
    this.submitted = true;
    this.quoteDetails.calculations = this.calculations;
    let modelObj = this.lvService.modelBinding(this.quoteDetailsForm, this.quoteDetails, 1);
    this.quoteDetails = modelObj;
    if (!this.quoteDetailsForm.valid) {
      this.scrollToError();
      return false;
    }


    let firstAnimalData;
    if (this.quoteDetails.livestock_details.length > 0) {
      firstAnimalData = this.quoteDetails.livestock_details[0];
    }

    if ((this.quoteDetails.livestock_meta.type_of_policy == 1) && (this.quoteDetails.livestock_meta.livestock_type_id == '10346458-ac55-4d2d-aef8-8c75101f8ce2')) {
      this.isAnimalExists = false;
    }
    else if ((this.quoteDetails.livestock_meta.type_of_policy == 2) && (this.quoteDetails.livestock_meta.livestock_type_id == '10146458-ac55-4d2d-aef8-8c75101f8ce2')
      && (firstAnimalData.cover_required == '70146458-ac55-4d2d-aef8-8c75101f8ce2')) {
      this.isAnimalExists = false;
    }
    else {
      this.isAnimalExists = true;
    }
    this.isSofTabEnable.emit(this.isAnimalExists);

    this.saveQuote(modelObj);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }


  saveQuote(modelObj) {
    if (!modelObj.prev_sequence_number) {
      modelObj.prev_sequence_number = modelObj.sequence_number;
    }
    modelObj.type = this.quote_type;
    if (this.route.snapshot.params.quote_type) {
      if (modelObj.prev_sequence_number == modelObj.sequence_number) {
        modelObj.id = null;
        if (modelObj.livestock_meta) {
          modelObj.livestock_meta.quote_id = null;
          modelObj.livestock_meta.id = null;
        }
        if (modelObj.financial_details) {
          modelObj.financial_details.quote_id = null;
          modelObj.financial_details.id = null;
        }
        if (modelObj.horse_details) {
          modelObj.horse_details.quote_id = null;
          modelObj.horse_details.id = null;
        }
        if (modelObj.policy_holder) {
          modelObj.policy_holder.quote_id = null;
          modelObj.policy_holder.id = null;
        }
        modelObj.statement_of_facts.forEach((res, index) => {
          res.quote_id = null;
          res.id = null;
        });

      }
    }

    let parent_quote = new Quote();
    parent_quote.id = modelObj.quote_meta_id;
    parent_quote.quote_versions[0] = modelObj;
    parent_quote.quote_versions[0].agreed_question_version = "";
    parent_quote.quote_versions[0].sof_version = "";
    let data = {
      quote_data: parent_quote
    };
    this.quoteService.saveLivestockQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            let temp;
            temp = this.quoteDetails;
            this.quoteDetails = response.result.quote_data.quote_versions[0];
            this.quoteDetails = this.lvService.bindQouteSaveApiResponse(this.quoteDetails, response.result.quote_data);
            this.quoteDetails.calculations = this.calculations;
            this.quoteDetails.prev_sequence_number = temp.prev_sequence_number;
            this.sessionSt.store('showHideMTAReasons', false);
            if (!this.route.snapshot.params.quote_type || this.route.snapshot.params.quote_type <= 3) {
              this.quoteDetailsUpdated.emit(this.quoteDetails);
              this.proceedToNextTab.emit();
            } else {
              this.router.navigateByUrl("/policy-list");
            }

          }
        });
  }
}
