import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Quote } from '../../../model/quote/quote.model';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { QuoteService } from '../../quote.service';
import { LivestockService } from '../livestock.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-lv-full-details',
  templateUrl: './lv-full-details.component.html',
  styleUrls: ['../../quote.component.css']
})
export class LvFullDetailsComponent implements OnInit {
  submitted = false;
  qdfrm: FormGroup;
  proposer_type_details: FormArray;
  claim_history: FormArray;
  @Input() selectedTabIndex;
  @Input() brokers;
  @Input() quoteModelDetails;
  quoteDetails = new QuoteVersions();
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  liveStocks = [];
  allClaims = [];
  AlllivestockCoverPeriods = [];
  animalTypes = [];
  claimFor = [];
  coverClaimFor = [];
  @Input() staticData;
  calculations = new CalculationsDetails();
  @Output() newSelectedTabIndex = new EventEmitter();

  constructor(private fb: FormBuilder, private quoteService: QuoteService, private cdRef: ChangeDetectorRef,
    private lvService: LivestockService, private modalService: NgbModal, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {
    this.qdfrm = this.fb.group({
      commonSections: [],
      proposer: [],
      pprOtherDetails: [],
      animals: [],
      saleAndClaim: []
    });
  }
  /*
 * Access form control values
 */
  get frmCtrl() {
    if (this.qdfrm) {
      return this.qdfrm.controls;
    }
  }


  isBullSelected: boolean = false;
  isEweSelected: boolean = false;
  isHeiferSelected: boolean = false;
  totalAnimalsSelected: any = [];
  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;

    if (this.selectedTabIndex == 6) {
      this.quoteDetails.calculations = this.lvService.bindCalculations(this.quoteModelDetails);
      this.calculations = this.quoteDetails.calculations;
    }

    if (this.route.snapshot.params.quote_type) {
      this.calculations = this.lvService.policyActions(this.route.snapshot.params.quote_type, this.calculations);
      this.quoteDetails.calculations = this.calculations;
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  changedSelectedTabIndex(index) {
    this.newSelectedTabIndex.emit(index);
  }


  updatedCalDetails;
  calDetails(data, dataFrom = '') {
    if (this.selectedTabIndex == 6) {
      this.updatedCalDetails = data;
      // Bind Broker commissions    
      this.calculations = this.lvService.brokerCommissions(dataFrom, data, this.brokers, this.calculations, this.quoteModelDetails, this.staticData);
      this.quoteDetails.calculations = this.calculations;
      // Animals
      let animal_details = [];
      if (this.qdfrm.value.animals) {
        if (this.qdfrm.value.animals.animal_details.length > 0) {
          animal_details = this.qdfrm.value.animals.animal_details;
        }
      }
      this.calculations = this.lvService.bindStaticCals(this.qdfrm.value, this.calculations, this.staticData, this.quoteDetails);
      this.calculations.full_calculate_data(this.quoteDetails, animal_details);
    }
  }


  onSubmit(helpTextPopupModal) {
    this.submitted = true;
    let modelObj = this.lvService.modelBinding(this.qdfrm, this.quoteDetails);
    this.quoteDetails = modelObj;
    if (!this.qdfrm.valid) {
      this.scrollToError();
      return false;
    }
    this.saveQuote(modelObj, helpTextPopupModal);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

  saveQuote(modelObj, helpTextPopupModal) {
    let parent_quote = new Quote();
    parent_quote.id = modelObj.quote_meta_id;
    parent_quote.quote_versions[0] = modelObj;
    parent_quote.quote_versions[0].quote_page_index = 4;
    let data = {
      quote_data: parent_quote
    };
    this.quoteService.saveLivestockQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            let temp;
            temp = this.quoteDetails;
            this.quoteDetails = response.result.quote_data.quote_versions[0];
            this.quoteDetails.prev_sequence_number = temp.prev_sequence_number;
            this.quoteDetails = this.lvService.bindQouteSaveApiResponse(this.quoteDetails, response.result.quote_data);
            this.quoteDetails.calculations = this.calculations;
            this.quoteDetailsUpdated.emit(this.quoteDetails);
            this.router.navigate(['/']);
            this.modalService.open(helpTextPopupModal, { centered: true });
          }
        });
  }

}
