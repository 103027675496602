import { Component, OnInit,Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { LivestockService } from '../livestock.service';

@Component({
  selector: 'app-lv-policy-payment',
  templateUrl: './lv-policy-payment.component.html',
  styleUrls: ['../../quote.component.css']
})
export class LvPolicyPaymentComponent implements OnInit {
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  @Input() quoteModelDetails;
  @Input() selectedTabIndex;
  quoteDetails = new QuoteVersions();
  calculations = new CalculationsDetails();

  constructor(private lvService: LivestockService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;
    if (this.selectedTabIndex == 5) {
      this.quoteDetails.calculations = this.lvService.bindCalculations(this.quoteModelDetails);
      this.calculations = this.quoteDetails.calculations;
    }
  }

  proceedBtn() {
    this.quoteDetailsUpdated.emit(this.quoteDetails);
      this.proceedToNextTab.emit();
  }

}
