import { Component, OnInit, Input, Output, EventEmitter, forwardRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder, Validators, FormGroup, FormArray,
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS
} from '@angular/forms';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { LivestockService } from '../livestock.service';
import { Quote } from '../../../model/quote/quote.model';
import { QuoteService } from '../../quote.service';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-sale-full-details',
  templateUrl: './sale-full-details.component.html',
  styleUrls: ['./sale-full-details.component.css']
})
export class SaleFullDetailsComponent implements OnInit {
  submitted = false;
  @Input() staticData;
  @Input() brokers;
  @Input() selectedTabIndex;
  @Input() quoteModelDetails= new QuoteVersions();
  quoteDetails = new QuoteVersions();
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  @Output() backToPrevTab = new EventEmitter();
  @Output() isSofTabEnable = new EventEmitter(false);
  isAnimalExists:boolean=false;
  calculations = new CalculationsDetails();
  @Output() newSelectedTabIndex = new EventEmitter();

  fullDetailSection: FormGroup;
  constructor(private fb: FormBuilder, private lvService: LivestockService, private quoteService: QuoteService, 
    private route: ActivatedRoute, private cdRef: ChangeDetectorRef) { 
    this.fullDetailSection = this.fb.group({
      animals: [],
      commonSections: [],
      proposer: [],
    });
  }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;
    if (this.route.snapshot.params.id) {
      if (this.selectedTabIndex == 1) {
        this.quoteDetails.calculations = this.lvService.bindCalculations(this.quoteModelDetails);
        this.calculations = this.quoteDetails.calculations;
      }
    } else {
      this.calDetails(this.quoteDetails.calculations);
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  changedSelectedTabIndex(index) {
    this.newSelectedTabIndex.emit(index);
  }

  backBtn() {
    this.backToPrevTab.emit();
  }

  updatedCalDetails;
  calDetails(data, dataFrom = '') {
    this.updatedCalDetails = data;
    // Bind Broker commissions    
    this.calculations = this.lvService.brokerCommissions(dataFrom, data, this.brokers, this.calculations, this.quoteModelDetails, this.staticData);
    this.quoteDetails.calculations = this.calculations;
    this.quoteModelDetails.calculations = this.calculations;
    // Animals
    let animal_details = [];
    if (this.fullDetailSection.value.animals) {
      if (this.fullDetailSection.value.animals.animal_details.length > 0) {
        this.quoteModelDetails.isButton = false;
        animal_details = this.fullDetailSection.value.animals.animal_details;
        this.fullDetailSection.value.animals.animal_details.forEach((res) => {
          if(this.quoteModelDetails.livestock_meta.type_of_policy == 2 && this.fullDetailSection.value.animals.animals_to_be_insured == "10246458-ac55-4d2d-aef8-8c75101f8ce2" && res.sale_price > 1000 && res.vet_cert_provided == 2) {
            this.quoteModelDetails.isButton = true;
          } else if(this.quoteModelDetails.livestock_meta.type_of_policy == 4 && (this.fullDetailSection.value.animals.animals_to_be_insured == "10146458-ac55-4d2d-aef8-8c75101f8ce2" || this.fullDetailSection.value.animals.animals_to_be_insured == "10346458-ac55-4d2d-aef8-8c75101f8ce2") && res.sale_price > 2000 && res.vet_cert_provided == 2) {
            this.quoteModelDetails.isButton = true;
          } else if(this.quoteModelDetails.livestock_meta.type_of_policy == 4 && this.fullDetailSection.value.animals.animals_to_be_insured == "10246458-ac55-4d2d-aef8-8c75101f8ce2" && res.sale_price > 3000 && res.vet_cert_provided == 2) {
            this.quoteModelDetails.isButton = true;
          }
        })
      }
    }
    this.calculations = this.lvService.bindStaticCals(this.fullDetailSection.value, this.calculations, this.staticData, this.quoteDetails);
    this.calculations.full_calculate_data(this.quoteDetails, animal_details);
  }

  onSubmit() {
    this.submitted = true;
    let modelObj = this.lvService.modelBinding(this.fullDetailSection, this.quoteDetails);
    this.quoteDetails = modelObj;
    if (!this.fullDetailSection.valid) {
      this.scrollToError();
      return false;
    }

    let firstAnimalData;
    if(this.quoteDetails.livestock_details.length > 0){
      firstAnimalData= this.quoteDetails.livestock_details[0];
    }

    if((this.quoteDetails.livestock_meta.type_of_policy == 3) && (this.quoteDetails.livestock_meta.livestock_type_id == '10346458-ac55-4d2d-aef8-8c75101f8ce2')){
      this.isAnimalExists = false;
    }
    else if((this.quoteDetails.livestock_meta.type_of_policy == 3) && (this.quoteDetails.livestock_meta.livestock_type_id == '10246458-ac55-4d2d-aef8-8c75101f8ce2')){
      this.isAnimalExists = false;
    }
    else{
      this.isAnimalExists =true;
    }
    this.isSofTabEnable.emit(this.isAnimalExists);

    let parent_quote = new Quote();
    parent_quote.id = modelObj.quote_meta_id;
    parent_quote.quote_versions[0] = modelObj;
    let data = {
      quote_data: parent_quote
    };
    this.quoteService.saveLivestockQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            let temp;
            temp = this.quoteDetails;      
            this.quoteDetails = response.result.quote_data.quote_versions[0];
            this.quoteDetails = this.lvService.bindQouteSaveApiResponse(this.quoteDetails, response.result.quote_data);                 
            this.quoteDetails.calculations = this.calculations;
            this.quoteDetails.prev_sequence_number = temp.prev_sequence_number;
            this.quoteDetailsUpdated.emit(this.quoteDetails);
            this.proceedToNextTab.emit();
          }
        });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }
}
