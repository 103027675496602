<div class="form_sections text-center">
    <div *ngIf="quoteDetails.is_referred && !quoteDetails.is_declined">
        <h4 *ngIf="quoteDetails">YOUR REFERRED QUOTATION REFERENCE IS - <a style="color: #383c74;" target="_blank"
                [routerLink]="['/livestock/quote-details',quoteDetails.id]">{{quoteDetails.quote_ref_number}}</a>
        </h4>
        <p class="f13 mt-2">YOUR QUOTATION HAS BEEN REFERRED TO OUR UNDERWRITERS </p>
        <p class="f13">
            We will contact you within 2 working days to confirm your final quotation details with you
        </p>
        <div class="form-group text-center mt-5">
            <a href="{{client_website_link}}" class="btn btn-success btn-sm">Back to main website</a>
        </div>
    </div>
    <div *ngIf="quoteDetails.is_declined">
        <h4>YOUR DECLINED QUOTATION REFERENCE IS - <a style="color: #383c74;" target="_blank"
                [routerLink]="['/livestock/quote-details',quoteDetails.id]">{{quoteDetails.quote_ref_number}}</a>
        </h4>
        <p class="f13 mt-2">Thank you for using 'Insure My Alpaca' for your insurance quotes.</p>
        <p class="f13">
            Unfortunately, on this occasion we are unable to offer you cover for the following reason:
        </p>
        <p class="f13" style="white-space: pre-line;">
            {{quoteDetails.declined_reasons}}
        </p>
        <p class="f13">
            If you would like to discuss this further or would like a arrange cover with us direct, then pleased
            email
            us at info@insuremyalpaca.co.uk.
        </p>
        <p class="f13">
            Customer Service Team<br>
            Insure My Alpaca
        </p>
        <div class="form-group text-center mt-5">
            <a href="{{client_website_link}}" class="btn btn-success btn-sm">Back to main website</a>
        </div>
    </div>
    <div *ngIf="!quoteDetails.is_referred && !quoteDetails.is_declined">
        <h4 class="">Your quotation Reference No. is: <a style="color: #383c74;" target="_blank"
                [routerLink]="['/livestock/quote-details',quoteDetails.id]">{{quoteDetails.quote_ref_number}}</a></h4>
        <p style="font-size: 15px;">THIS IS A QUOTATION ONLY; THIS WILL NOT BE CONVERTED TO HOLDING COVER UNTIL PAYMENT
            HAS BEEN
            RECEIVED.
            <br><br>
            Your quotation schedule and quotation documents have been emailed to you; these include;<br><br>
            Quotation schedule<br>
            Policy wording<br>
            IPID<br>
            Broker Terms of Business<br>
            Broker Fair Processing Notice<br><br>
            If you want to proceed to taking out a policy then please click on the payment button below

        </p>
        <div class="mt-4">
            <h5 class="text-center">POLICY PAYMENT TERMS</h5>
            <div class="mt-3 row">
                <div class="col-md-10 offset-1 text-left">
                    <ul class="f13" style="margin-left: 40px;">
                        <li>Payment is due within 14 days of your policy effective date, if payment is not
                            received
                            within this period your policy may be cancelled</li>
                        <li>If you would like to pay by credit/ debit card please call us on 01228 406290</li>
                        <li>If you would like to pay by monthly instalments (provided by Premium Credit Limited)
                            then
                            please call us on 01228 406290</li>
                        <li>
                            If you would like to make a direct bank transfer please use the details below
                            quoting your
                            policy number;
                            <ul class="f13">
                                <li>Bank Account Sort Code: 40-05-30</li>
                                <li>Bank Account no: 64578694</li>
                                <li>Bank Account Name: The Broker Network Ltd RE H&H INSURANCE BROKERS</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <button class="btn btn-default btn-success float-right" (click)="proceedBtn(converttopolicymodal)">Proceed to
            policy & payment</button>
    </div>
</div>

<ng-template #converttopolicymodal let-modal>
    <div class="modal-body">
        <p>We assume you have all the quote data. Are you sure you want to convert <b>{{ quoteDetails.quote_ref_number
                }} </b> into policy?</p>
        <div class="form-group">
            <label>Policy Start Date</label>
            <div class="w-100">
                <input type="text" readonly required name="policy_start_date"
                    [(ngModel)]="quoteDetails.policy_start_date" #policy_start_date="ngModel"
                    placeholder="Policy Start Date" style="height: 31px;"
                    class="form-control form-control-sm datepicker" matInput [min]="minPolicyDate" [max]="maxPolicyDate"
                    [matDatepicker]="myDatepicker" [ngClass]="{ 'is-invalid': disablIfPastDate }"
                    (dateChange)="onPolicyStartDateChange($event)">
                <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="convertToPolicyPost()"
            [disabled]="disablIfPastDate">Convert to Policy</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    </div>
</ng-template>