import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from "@angular/router";
import { SharedService } from '../services/shared.service';
import { environment } from '../../environments/environment';
import { QuoteService } from '../quote/quote.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
logo_url: string = '';
  
  constructor(private sessionSt: LocalStorageService, private router: Router, private quoteService: QuoteService) { }

  ngOnInit(): void {
    // Get Broker data
    this.quoteService.getLiveStockLogo()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.logo_url = environment.imageURL + response.result.logo_path;
          }
        });
  }

  isMobileNav: boolean = false;
  mobileNav() {
    if (this.isMobileNav) {
      this.isMobileNav = false;
    } else {
      this.isMobileNav = true;
    }
  }

}
