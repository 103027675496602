<div class="text-center"> 
    <div *ngIf="quoteModelDetails.is_referred && !quoteModelDetails.is_declined">
        <h4 *ngIf="quoteModelDetails">YOUR REFERRED QUOTATION REFERENCE IS - <a style="color: #383c74;" target="_blank"
                [routerLink]="['/livestock/policy',quoteModelDetails.id]">{{quoteModelDetails.policy_ref_number}}</a>
        </h4>
        <p class="f13 mt-2">YOUR QUOTATION HAS BEEN REFERRED TO OUR UNDERWRITERS </p>
        <p class="f13">
            We will contact you within 2 working days to confirm your final quotation details with you
        </p>
        <div class="form-group text-center mt-5">
            <a routerLink="/dashboard" class="btn btn-success btn-sm">Back to dashboard</a>
        </div>
    </div>
    <div *ngIf="quoteModelDetails.is_declined">
        <h4>YOUR DECLINED QUOTATION REFERENCE IS - <a style="color: #383c74;" target="_blank"
                [routerLink]="['/livestock/policy',quoteModelDetails.id]">{{quoteModelDetails.policy_ref_number}}</a>
        </h4>
        <p class="f13 mt-2">Thank you for using 'Insure My Alpaca' for your insurance quotes.</p>
        <p class="f13">
            Unfortunately, on this occasion we are unable to offer you cover for the following reason:
        </p>
        <p class="f13" style="white-space: pre-line;">
            {{quoteModelDetails.declined_reasons}}
        </p>
        <p class="f13">
            If you would like to discuss this further or would like a arrange cover with us direct, then pleased
            email
            us at info@insuremyalpaca.co.uk.
        </p>
        <p class="f13">
            Customer Service Team<br>
            Insure My Alpaca
        </p>
        <div class="form-group text-center mt-5">
            <a routerLink="/dashboard" class="btn btn-success btn-sm">Back to dashboard</a>
        </div>
    </div>
    <div *ngIf="!quoteModelDetails.is_referred && !quoteModelDetails.is_declined">
        <h4>YOUR POLICY REFERENCE IS - <a style="color: #383c74;" target="_blank"
                [routerLink]="['/livestock/policy',quoteModelDetails.id]">{{quoteModelDetails.policy_ref_number}}</a>
        </h4>
        <p class="f13">Your policy is now in place, please ensure all the infomration is accurate.</p>
        <p class="f13">
            Your policy documents will be emailed to you, these will be;
        </p>
        <div class="mt-3 row">
            <div class="col-md-4 offset-4 text-left">
                <ul class="f13" style="margin-left: 84px;">
                    <li>Policy Schedule</li>
                    <li>Policy Wording</li>
                    <li>Livestock Smallholders IPID</li>
                </ul>
            </div>
        </div>

        <h4 class="mt-4">YOUR POLICY PAYMENT TERMS</h4>
        <p class="f13">Your policy invoice will be emailed to you, please keep this for your records.</p>
        <p class="f13 mt-2">You will need to make full payment for you policy within the next 14 days, please refer to
            your
            invoice for payment terms and conditions. </p>
        <p class="f13 mt-2">If you do not make payment within this period your policy will be cancelled.</p>
        <div class="mt-3 row">
            <div class="col-md-10 offset-1 text-left">
                <ul class="f13" style="margin-left: 84px;">
                    <li>Payment is due within 14 days of your policy effective date, if payment is not received
                        within this period your policy may be cancelled</li>
                    <li>If you would like to pay by credit/ debit card please call us on 01228 406290</li>
                    <li>If you would like to pay by monthly instalments (provided by Premium Credit Limited) then
                        please call us on 01228 406290</li>
                    <li>
                        If you would like to make a direct bank transfer please use the details below quoting your
                        policy number;
                        <ul class="f13">
                            <li>Bank Account Sort Code: 40-05-30</li>
                            <li>Bank Account no: 64578694</li>
                            <li>Bank Account Name: The Broker Network Ltd RE H&H INSURANCE BROKERS</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="form-group text-center mt-5" *ngIf="quoteModelDetails.livestock_meta.type_of_policy < 3">
            <button class="btn btn-success btn-sm" (click)="proceedBtn()">Proceed</button>
        </div>
        <div class="form-group text-center mt-5" *ngIf="quoteModelDetails.livestock_meta.type_of_policy >= 3">
            <a class="btn btn-success btn-sm" [routerLink]="['/policy-list']">Back to Dashboard</a>
        </div>
    </div>
</div>