export class FinancialDetails {
    id: string = '';
    quote_id: string = '';
    policy_id: string = '';
    peliwica_pay_away_commission: number = 0.0;
    broker_commission: number = 0.0;
    peliwica_retained_commission: number = 0.0;
    is_aeio: number = 1;
    class_net_rate: number = 0.0;
    net_rate_amount: number = 0.0;
    class_minimum_premium: number = 0.0;
    class_section_loading: number = 0.0;
    main_discount: number = 0.0;
    vet_excess_discount: number = 0.0;
    public_liability_amount: number = 0.0;
    personal_accident_amount: number = 0.0;
    saddlery_net_rate: number = 0.0;
    saddlery_minimum_premium: number = 0.0;
    trailer_net_rate: number = 0.0;
    trailer_minimum_premium: number = 0.0;
    ipt_fee: number = 0.0;
    uw_fee: number = 0.0;
    policy_premium: number = 0.0;
    total_premium: number = 0.0;
    fees: number = 0.0;
    total_policy_cost: number = 0.0;
    broker_amount: number = 0.0;
    premium_loading_percentage: number = 0.0;
    premium_loading: number = 0.0;
    full_vet_gross_amount: number = 0.0;
    net_vet_fee_amount: number = 0.0;
    total_net_amount: number = 0.0;
    net_co_insurance_towards_lameness_diagnostics: number = 0.0;
    net_personal_accident_amount: number = 0.0;
    net_public_liability_amount: number = 0.0;
    tigerlab_premium_loading: number = 0;
    old_premium_loading_percentage: number = 0;
}
