import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../quote/quote.service';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { UserInfoService } from '../services/user-info.service';

@Component({
  selector: 'app-quote-pre-qualifier',
  templateUrl: './quote-pre-qualifier.component.html',
  styleUrls: ['../quote/quote.component.css']
})
export class QuotePreQualifierComponent implements OnInit {

  constructor(private quoteService: QuoteService, private route: ActivatedRoute, private router: Router,
    private userService: UserInfoService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      let url = event.url.split('/');
      this.routeType = parseInt(url[2]);
      this.getQuestions();
    });
  }

  agreedQuestions;
  ProductId: string = '';
  routeType: number = 0;
  ngOnInit(): void {
    this.routeType = this.route.snapshot.params.type;

    // GET AGREED QUESTIONS
    this.getQuestions();
  }

  getQuestions() {
    this.quoteService.getAgreedQues(environment.LIVESTOCK_ID, 0)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.agreedQuestions = response.result.agreed_questions;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * On Agree Button Click
  */
  agreedBtnAction() {
    this.router.navigate(['/quote']);
  }
}
