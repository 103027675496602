import { PolicyHolderAddress } from './policy_holder_address.model';
export class PolicyHolder {
    id: string = '';
    quote_id: string = '';
    policy_id: string = '';
    address_id: string = '';
    first_name: string = '';
    last_name: string = '';
    phone: string = '';
    email: string = '';
    dob: string = '';
    status: number = null;
    company_name: string = '';
    trading_name: string = '';
    proposer_type: number = 0;
    mobile: string = '';
    year_of_establishment: number = null;
    is_micro_enterprise: number = null;
    address:PolicyHolderAddress = new PolicyHolderAddress();
}
